export const CHV2O = {
    title: "Civics and Citizenship",
    description: {
        code: "CHV2O",
        department: "Canadian and World Studies",
        type: "Open",
        credit: "0.5",
        grade: "10",
        prerequisite: "None",
        document: "Canadian and World Studies, The Ontario Curriculum, Grades 9 and 10, 2013",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/canworld910curr2013.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course explores rights and responsibilities associated with being an active citizen in a democratic society. Students will explore issues of civic importance such as healthy schools, community planning, environmental responsibility, and the influence of social media, while developing their understanding of the role of civic engagement and of political processes in the local, national, and/or global community. Students will apply the concepts of political thinking and the political inquiry process to investigate, and express informed opinions about, a range of political issues and developments that are both of significance in today’s world and of personal interest to them. ",
    },
    outline: {
        title: "CHV2O Civics and Citizenship- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Civic Awareness",
                hour: "20 Hours",
                content: "Civics is the study of how people use politics, laws, words and actions to govern themselves. This unit teaches the levels of the government, Who's Who in Government; The Changing face of Political Parties; and  The Way Political Parties Work.",
            },
            {
                title: "Unit Two: Government and the Political Process",
                hour: "20 Hours",
                contetn: "In this Unit, students will learn about the different branches of Canadian government: federal, provincial and municipal. Students will participate in a “passing a bill into law” activity. ",
            },
            {
                title: "Unit Three: Global Civics",
                hour: "10 Hours",
                content: "In this final unit, we'll consider some of Canada's responses to challenges and changes around the globe. We'll evaluate Canada's participation in a number of international organizations. Finally, we'll analyse what it means to be a global citizen in today's world.",
            },
            {
                title: "Exam",
                hour: "5 Hours",
                content: "RST: The summative is worth 30% of final mark and students will complete a summative assignment that is designed to assess your knowledge of the course, as well as your ability to reflect on your learning and present this information in a professional manner.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "CHV2O: Civics and Citizenship ,Grade 10",
        content: [
            {
                title: "Political Inquiry and Skill Development",
                overall: [
                    "Political Inquiry: use the political inquiry process and the concepts of political thinking when investigating issues",
                    "Developing Transferable Skills: apply in everyday contexts skills developed through investigations related to civics and citizenship education",
                ],
            },
            {
                title: "Civic Awareness",
                overall: [
                    "Civic Issues, Democratic Values: describe beliefs and values associated with democratic citizenship in Canada, and explain how they are related to civic action and to one's position on civic issues (Focus on: Political Significance, Political Persepective)",
                    "Governace in Canada: explain, with reference to a range of issues of civic importance, the roles and responsibilities of various institutions, structures, and figures in Canadian governance (Focus on : Stability and Change; Political Perspective )",
                    "rights and ResponsibilitiesL analyse key rights and responsibilities associated with citizenship, in both the Canadian and global context, and some ways in which these rights are protected (Focus on:Political Significance, Ojectives and Results)",
                ]
            },
            {
                title: "Civic Engagement and Action",
                overall: [
                    "Civic Contributions: analyse a variety of civic contributions, and ways in which people can contribute to the commmon good (Focus on: Political Significance, Objectives and Results)",
                    "Inclusion and Participation: assess ways in which people express their perspectives on issues of civic importance and how various perspectives, beliefs, and values are recongnized and represented in communities in Canada (Focus on : Political Significance, Political Perspective)",
                    "Personal Action on Civic Issues: analyse a civic issue of personal inteset and develop a plan of action to address it (Focus on : Plitical Significance, Objectvices an Results)",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Civics and Citizenship CHV2O provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent learning tutorials, short essays, critical analysis, digital media projects. Presentation techniques form the basis of study as students create oral presentations through screencasts, and audio files in conjunction with Canadian and World studies-related assignments that reflect their understanding of issues about Civics and Citizenship.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "The political inquiry process (formulate questions, gather and organize, interpret and analyse, evaluate and draw conclusions and communicate) enhances students to develop and refine their critical and creative skills, problem-solving skills, and communication skills, guiding students in their investigations of issues, events, developments, policies, and/or plans of action",
            "Videos in the course illustrate topics such as Civic Awareness, Government and the Political Process and Global Civics",
            "Scaffolding longer Canadian and World studies-related assignments allow students to work with the process of the political inquiry. Teacher feedback at each level enables students to improve both style and content in their projects.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their assessments.",
            "The inquiry process is practiced throughout the units to prepare students for the next courses.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Civics_and_Citizenship: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}