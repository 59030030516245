export const MTH1W = {
    title: "Mathematics",
    description: {
        code: "MTH1W",
        department: "Mathematics",
        type: "Academic",
        grade: "9",
        credit: "1.0",
        prerequisite: "None",
        document: "Mathematics, The Ontario Curriculum, Grades 9 and 10, 2005",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/2009Mathematics11_12.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course enables students to consolidate, and continue to develop, an understanding of mathematical concepts related to number sense and operations, algebra, measurement, geometry, data, probability, and financial literacy. Students will use mathematical processes, mathematical modelling, and coding to make sense of the mathematics they are learning and to apply their understanding to culturally responsive and relevant real-world situations. Students will continue to enhance their mathematical reasoning skills, including proportional reasoning, spatial reasoning, and algebraic reasoning, as they solve problems and communicate their thinking.",
    },
    outline: {
        title: "MTH1W, Grade 9 Mathematics",
        units: [
            {
                title: "Unit One: Number",
                hour: "15 Hours",
                content: "In this unit, students will continue to explore connections between diverse number systems, the cultural development of number concepts, and real-life applications. They will apply what they have learned about positive fractions, positive decimal numbers, and integers to negative fractions and negative decimal numbers. Students also broaden their knowledge and abilities in percentages, ratios, rates, and proportions in order to draw more real-world connections.",
            },
            {
                title: "Unit Two: Algebra and Coding",
                hour: "29 Hours",
                contetn: "In this unit, students continue to deepen their grasp of algebra by drawing links between algebra and numbers while generalising relationships using algebraic expressions and equations. Students will use their coding abilities to dynamically portray events, analyse mathematical ideas, and solve problems in a variety of circumstances. Students will be introduced to several representations of linear and nonlinear relationships, which they will study in greater depth in subsequent secondary mathematics courses. Students learn about the constant rate of change and beginning values of linear relationships and solve associated real-world problems.",
            },
            {
                title: "Unit Three: Data",
                hour: "20 Hours",
                content: "In this unit, students will build on the fundamentals of data gathering and analysis to culminate in a project in which students conduct a survey and evaluate their results. Students will develop and evaluate a mathematical model for their data.",
            },
            {
                title: "Unit Four: Geometry and Measurement",
                hour: "20 Hours",
                content: "Students will demonstrate an understanding of the properties of mechanical waves and sound and of the principles underlying their production, transmission, interaction, and reception. They will investigate the properties of mechanical waves and sound, and solve related problems. They will analyse how mechanical waves and sound affect technology, structures, society, and the environment, and assess ways of reducing their negative side effects.",
            },
            {
                title: "Unit Five: Financial Literacy",
                hour: "23 Hours",
                content: "In this unit, students will  extend their financial literacy knowledge to answer questions related to appreciation and depreciation and explain how budgets can be modified based on changes in circumstances. Students compare the effects of different interest rates, down payments, and other factors associated with purchasing goods and services. Students use their learning from other strands to solve financial problems of interest.                ",
            },
            {
                title: "Exam",
                hour: "3 Hours",
                content: "The final exam will be proctored and worth 30% of your final grade.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "MTH1W,Grade 9, Mathematics",
        content: [
            {
                title: "Social-Emotional Learning (SEL) Skills In Mathematics",
                overall: [
                    "apply the mathematical processes to develop a conceptual understanding of, and procedural fluency with, the mathematics they are learning",
                    "make connections between mathematics and various knowledge systems, their lived experiences, and various real-life applications of mathematics, including careers.",
                    "Develop and explore a variety of social-emotional learning skills in a context that supports and reflects this learning in connection with the expectations across all other strands.",
                ]
            },
            {
                title: "Number And Coding",
                overall: [
                    "demonstrate an understanding of the development and use of numbers, and make connections between sets of numbers.",
                    "represent numbers in various ways, evaluate powers, and simplify expressions by using the relationships between powers and their exponents.",
                    "apply an understanding of rational numbers, ratios, rates, percentages, and proportions, in various mathematical contexts, and to solve problems",
                ]
            },
            {
                title: "Foundations",
                overall: [
                    "demonstrate an understanding of the development and use of algebraic concepts and of their connection to numbers, using various tools and representations",
                    "apply coding skills to represent mathematical concepts and relationships dynamically, and to solve problems in algebra and across the other strands",
                    "represent and compare linear and non-linear relations that model real-life situations, and use these representations to make predictions",
                    "demonstrate an understanding of the characteristics of various representations of linear and non-linear relations, using tools, including coding when appropriate",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Using a variety of instructional strategies, the teacher will provide numerous opportunities for students to develop skills of inquiry, problem solving, and communication as they investigate and learn fundamental concepts. The integration of critical thinking and critical inquiry skills will provide a powerful tool for reasoning and problem solving, and is reflected in a meaningful blend of both process and content. ",
        p2: "This course will focus on developing students’ critical thinking and inquiry skills, in order to develop their appreciation of, and engagement with, the multilayered subject matter. The goal of this focus on critical thinking and inquiry skills is to support students in attempting to reach beyond superficial conclusions and move towards deeper understanding.",
        lst: [
            "The inquiry process consists of formulating questions; gathering and organizing evidence; interpreting and analyzing evidence; evaluating evidence and drawing conclusions; and communicating findings.",
            "Critical thinking then applies the steps of the inquiry process toward examinations of opinions, values, biases and meanings.",
            "Teachers will support students’ development of these skills by modeling in the classroom, providing ongoing and varied opportunities to exercise.",
            "Assessment and evaluation will focus on students’ effective use of critical thinking skills, and not solely on a traditional “product”.",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Math: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },

}