import "../../styles/PageStyle/Home.css";
import Upperdown from "../../components/ToolComp/Upperdown";
import TwoColGrid from "../../components/ToolComp/twoColGrid";
import ImageGallery from '../../components/ToolComp/PhotoGallery';

function Home() {
  return <div className="Home">
    <div className="main-section">
      <div className="section">
        <Upperdown img="img/KAI.png" title="About KAI School" context={["KAI School is committed to providing excellent academic education, aiming to ignite students' curiosity while fostering critical thinking and problem-solving skills. We prioritize the comprehensive development of students by promoting a diverse and inclusive learning environment that values each student's uniqueness. This approach encourages the exchange of different perspectives and cultures.","We actively promote innovative thinking and the practical application of knowledge through project-based learning and experiences. This enables students to showcase their creativity and problem-solving capabilities across various fields. KAI School is fully committed to nurturing students into responsible citizens who demonstrate a caring attitude towards society, actively engage in philanthropic activities, and contribute to positive social revolution.", "KAI School remains dedicated to collaborating with students and parents alike, ensuring all learners accomplish their objectives towards obtaining an Ontario Secondary School Diploma (OSSD)."]} />
      </div>
      <div className="Vision section">
        <img class="image-divider"
          src="/img/img-divider.png" />
        <TwoColGrid img="img/bookshell.jpg" title="Vision" content={["KAI school's vision is to become an excellent high school that inspires students to pursue excellence, innovative thinking, and global leadership. We are committed to providing high-quality academic education that leads the way into the future, cultivating students to become responsible global citizens capable of success in a multicultural society. By emphasizing innovation, social responsibility, and a global perspective, our school aims to be a leader in the growth and development of students, making a positive contribution to societal change and progress."]} reversed={false} />
      </div>
      <div className="Mission section">
        <TwoColGrid img="img/library-2.jpg" title="Mission" content={["KAI School is dedicated to inspiring innovation and nurturing leadership, with a primary focus on delivering outstanding academic education. Our goal is to shape students into well-rounded individuals equipped with critical thinking skills and a global perspective, making students become responsible global citizens. Through the provision of high-quality academic programs, we empower students to unlock their full potential and achieve remarkable academic success. Emphasizing the development of leadership and teamwork skills, we prepare students for future careers and active participation in society. KAI School provides a range of diverse learning experiences, fostering innovative thinking and practical application, allowing students to excel in various fields. Our commitment extends to molding students with a sense of social responsibility, ethical principles, and cultural sensitivity. We strive to establish a vibrant, inclusive, and creative school culture, delivering comprehensive education to ensure all students success in facing future challenges."]} reversed={true} />
      </div>
      <div className="photo-gallery-container section">
      <h2>Student Activities</h2>
  <p>Welcome to our gallery of student activities! Explore the diverse range of events that enhance our students' experiences, from academic challenges to cultural celebrations.</p>
  
  <blockquote>“Participating in these events has not only helped me grow academically but also personally. I’ve made lifelong friends through these activities!” - Jane Doe, Student</blockquote>
  
  <ImageGallery />

  <div className="event-cta">
    <p>Interested in joining an activity? <a href="/#/contact/contactus">Sign up here</a> to get involved and make the most of your student life!</p>
  </div>
      </div>

    </div>

  </div>;
}

export default Home;
