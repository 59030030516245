export const CHC2D = {
    title: "Canadian History Since World War I",
    description: {
        code: "CHC2D",
        department: "Canadian and World Studies",
        type: "Academic",
        credit: "1.0",
        grade: "10",
        prerequisite: "None",
        document: "Canadian and World Studies, The Ontario Curriculum, Grades 9 and 10, 2013",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/canworld910curr2013.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course explores social, economic, and political developments and events and their impact on the lives of different individuals, groups, and communities, including First Nations, Métis, and Inuit individuals and communities, in Canada since 1914. Students will examine the role of conflict and cooperation in Canadian society, Canada’s evolving role within the global community, and the impact of various individuals, organizations, and events on identities, citizenship, and heritage in Canada. Students will develop an understanding of some of the political developments and government policies that have had a lasting impact on First Nations, Métis, and Inuit individuals and communities. They will develop their ability to apply the concepts of historical thinking and the historical inquiry process, including the interpretation and analysis of evidence, when investigating key issues and events in Canadian history since 1914.",
    },
    outline: {
        title: "CHC2D Canadian History Since World War I- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Canada, 1914-1929",
                hour: "25 Hours",
                content: "This unit will lay the groundwork for a study of Canada since World War I. This introductory unit will cover topics such as social change, inventions and technological innovations, lifestyle changes, the role of women, and the war to end all wars, World War One.",
            },
            {
                title: "Unit Two: Canada, 1929-1945",
                hour: "25 Hours",
                contetn: "The second unit of the course will focus on the economic prosperity that Canada experienced in the 1920’s and how this all changed with the Stock Market Crash in 1929. The years to come were desperate for the nation’s people. Students will gain an understanding of life at the time, as well as the hardships that people had to endure. They will also have the opportunity to learn about Canada's role in World War II.",
            },
            {
                title: "Unit Three: Canada, 1945-1982",
                hour: "25 Hours",
                content: "This unit looks into the role of Canada in North America, the country’s political climate and Canada in the global view. Within North America, the economic situation was changing quickly with the formation of the labour movement. Students will continue to learn about the changes taking place in Canada’s political climate as a new province and flag are introduced and they will learn about the global involvement that Canada begins to become known for Peacekeeping.",
            },
            {
                title: "Unit Four: Canada, 1982-Present",
                hour: "25 Hours",
                content: "This time period was a time of many economic and political changes. Students will learn about Prime Minister Pierre Trudeau and the involvement of his government in the lives of Canadians of this time. One province which experienced strong political changes was Quebec.",
            },
            {
                title: "Exam/RST",
                hour: "8 Hours",
                content: "RST: The summative is worth 20% of final mark and students will complete a summative assignment that is designed to assess your knowledge of the course, as well as your ability to reflect on your learning and present this information in a professional manner. You will create a Website to serve as your course portfolio. ",
            },
            {
                title: "RST",
                hour: "2 Hours",
                content: "RST: The summative is worth 20% of final mark and students will complete a summative assignment that is designed to assess your knowledge of the course, as well as your ability to reflect on your learning and present this information in a professional manner. You will create a Website to serve as your course portfolio.  This is a proctored exam worth 10% of your final grade.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "CHC2D: Canadian History Since World War I ,Grade 10",
        content: [
            {
                title: "Historical Inquiry And Skill Development",
                overall: [
                    "Historical Inquiry: use the historical inquiry process and the concepts of historical thinking when investigating aspects of Canadian history since 1914",
                    "Developing Transferable Skills: apply in everyday contexts skills developed through historical investigation, and identify some careers in which these skills might be useful",
                ],
            },
            {
                title: "Canada, 1914–1929",
                overall: [
                    "Social, Economic, and Political Context: describe some key social, economic, and political events, trends, and developments between 1914 and 1929, and assess their significance for different groups and communities in Canada, including First Nations, Métis, and Inuit communities (FOCUS ON: Historical Significance; Historical Perspective)",
                    "Communities, Conflict, and Cooperation: analyse some key interactions within and between different communities in Canada, including First Nations, Métis, and Inuit communities, and between Canada and the international community, from 1914 to 1929, and how these interactions affected Canadian society and politics (FOCUS ON: Historical Significance; Cause and Consequence)",
                    " Identity, Citizenship, and Heritage: explain how various individuals, organizations, and specific social changes between 1914 and 1929 contributed to the development of identities, citizenship, and heritage in Canada (FOCUS ON: Continuity and Change; Historical Perspective)",
                ]
            },
            {
                title: "Canada, 1929–1945",
                overall: [
                    "Social, Economic, and Political Context: describe some key social, economic, and political events, trends, and developments between 1929 and 1945, and assess their impact on different groups and communities in Canada, including First Nations, Métis, and Inuit communities (FOCUS ON: Cause and Consequence; Historical Perspective)",
                    "Communities, Conflict, and Cooperation: analyse some key interactions within and between communities in Canada, including First Nations, Métis, and Inuit communities, and between Canada and the international community, from 1929 to 1945, with a focus on key issues that affected these interactions and changes that resulted from them (FOCUS ON: Cause and Consequence; Continuity and Change)",
                    "Identity, Citizenship, and Heritage: explain how various individuals, groups, and events, including some major international events, contributed to the development of identities, citizenship, and heritage in Canada between 1929 and 1945 (FOCUS ON: Historical Significance; Historical Perspective)",
                ]
            },
            {
                title: "Canada, 1945–1982",
                overall: [
                    "Social, Economic, and Political Context: describe some key social, economic, and political events, trends, and developments in Canada between 1945 and 1982, and assess their significance for different individuals, groups, and/or communities in Canada, including First Nations, Métis, and Inuit individuals and communities (FOCUS ON: Historical Significance; Continuity and Change)",
                    "Communities, Conflict, and Cooperation: analyse some key experiences of and interactions between different communities in Canada, including First Nations, Métis, and Inuit communities, as well as interactions between Canada and the international community, from 1945 to 1982 and the changes that resulted from them (FOCUS ON: Continuity and Change; Historical Perspective)",
                    "Identity, Citizenship, and Heritage: analyse how significant events, individuals, and groups, including Indigenous peoples, Québécois, and immigrants, contributed to the development of identities, citizenship, and heritage in Canada between 1945 and 1982 (FOCUS ON: Historical Significance; Cause and Consequence)",
                ]
            },
            {
                title: "Canada, 1982 To The Present",
                overall: [
                    "Social, Economic, and Political Context: describe some key social, economic, and political events, trends, and developments in Canada from 1982 to the present, and assess their significance for different groups and communities in Canada, including First Nations, Métis, and Inuit communities (FOCUS ON: Historical Significance; Continuity and Change)",
                    "Communities, Conflict, and Cooperation: analyse some significant interactions within and between various communities in Canada, including First Nations, Métis, and Inuit communities, and between Canada and the international community, from 1982 to the present, and how key issues and developments have affected these interactions (FOCUS ON: Continuity and Change; Historical Perspective)",
                    "Identity, Citizenship, and Heritage: analyse how various significant individuals, groups, organizations, and events, both national and international, have contributed to the development of identities, citizenship, and heritage in Canada from 1982 to the present (FOCUS ON: Historical Significance; Cause and Consequence)",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Canadian History since World War I CHC2D provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent learning tutorials, short essays, critical analysis, poster and cartoon creation and digital media projects. Presentation techniques form the basis of study as students create oral presentations through screencasts, and audio files in conjunction with history-related assignments that reflect their understanding of issues in Canadian History since World War I.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "The historical inquiry process (formulate questions, gather and organize, interpret and analyse, evaluate and draw conclusions and communicate) enhances students to develop and refine their critical and creative skills, problem-solving skills, and communication skills, guiding students in their investigations of issues, events, and ideas.",
            "Videos in the course illustrate topics such as Canada from 1914 to now.",
            "Scaffolding longer history-related assignments allow students to work with the process of the historical inquiry. Teacher feedback at each level enables students to improve both style and content in their projects.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their assessments.",
            "The inquiry process is practiced throughout the units to prepare students for the next courses.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "20%",
                "10%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "RST",
                "Final Exam"
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            CANADIAN_HISTORY_SINCE_WORLD_WAR_I: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}