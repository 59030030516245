import covertTabData from "../../helper/convertData";
import TabPanel from "../ToolComp/TabPanel";
import Divider from "../ToolComp/divider";

function Assessment(props) {
    const tabData = covertTabData(props.assessment.tab);

    return (
        <div className="assessment section">
            <h2>Strategies for Assessment and Evaluation of Student Performance</h2>
            <Divider />
            <h4 style={{ textAlign: "center" }}>{props.assessment.content}</h4>
            <div className="content flex-container">
                <div className="flex-img">
                    <img src="/img/KAI.png" alt=""></img>
                </div>
                <TabPanel tab={tabData} />

                </div>
            </div>

    );
}
export default Assessment;