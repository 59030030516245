export const ELS2O = {
    title: "Literacy Skills: Reading and Writing",
    description: {
        code: "ELS2O",
        department: "English",
        type: "Open",
        credit: "1.0",
        grade: "10",
        prerequisite: "English, Grade 9, Academic or Applied, or a Grade 9 English LDCC (locally developed compulsory credit) course",
        document: "The Ontario Curriculum, Grades 9 and 10: English, 2007 (Revised)",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/english910currb.pdf",
        developer: "KAI School",
        date: 2022,
        content: "This course is designed to help students strengthen essential reading and writing skills,providing them with the extra literacy support they need in order to graduate. Studentswill read informational, graphic, and literary texts, with a focus on locating information,identifying main ideas and supporting details, building vocabulary, and consolidatingskills in the application of key comprehension strategies. The course will also helpstudents develop core learning strategies.",
    },
    outline: {
        title: "ELS2O Business- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Literacy and Grammar",
                hour: "20 Hours",
                content: "Students will explore literacy and its importance in everyday life. They will also be introduced to the process of reading and writing, as well as basic grammar concepts that will help them understand the next unit. The students will start their learning journal at this point.",
            },
            {
                title: "Unit Two: Informational and Graphic Texts",
                hour: "20 Hours",
                contetn: "Students will become familiar with informational and graphic texts as well as several exercises to help them read, understand, and create a personal informational and graphic text.",
            },
            {
                title: " Unit Three: Literary Texts, Narrative Texts and Summaries",
                hour: "20 Hours",
                content: "The students will read and understand different literary texts. They will learn grammar concepts that will enhance their writing of summaries and narrative texts. They will learn how to sum up any text as well as learn how to compose narrative texts.",
            },
            {
                title: "Unit Four: News Writing and Opinion Essays",
                hour: "25 Hours",
                content: "Students will study news writing, and opinion essay sin this unit. They will learn how to write news leads and opinion essays supported by grammar concepts appropriate to both writing styles. This will be supported by critical reading of news and opinion pieces.",
            },
            {
                title: "Unit Five: Oral Literacy",
                hour: "20 Hours",
                content: "Among other things, students will learn the importance of vocabulary, fluency, and diction. They will also learn the importance of political correctness. Finally, students will learn critical techniques to improve their communication skills",
            },
            {
                title: "Rich Summative Task",
                hour: "5 Hours",
                content: "RST worth 30% of final mark: Students will complete a summative that includes content that has been worked on through the course, plus an oral metacognitive component.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "ELS2O: Literacy Skills: Reading and Writing ,Grade 10",
        content: [
            {
                title: "Reading Skills",
                overall: [
                    "Reading for Meaning: read and demonstrate an understanding of a variety of contemporary literary, informational, and graphic texts, using a range of strategies to construct meaning;",
                    "Understanding Form and Style: recognize a variety of text forms, text features, and stylistic elements and demonstrate an understanding of how they help communicate meaning; ",
                    "Reading With Fluency: use knowledge of words and cueing systems to read fluently;",
                    "Reflecting on Reading Skills and Strategies: reflect on and identify their strengths as readers, areas for improvement, and the strategies they found most helpful before, during, and after reading.",
                ],
            },
            {
                title: "Writing Skills",
                overall: [
                    "Developing and Organizing Content: generate, gather, and organize ideas and information to write for an intended purpose and audience;",
                    "Using Knowledge of Form and Style: draft and revise their writing, using a variety of informational, literary, and graphic forms and stylistic elements appropriate for the purpose and audience;",
                    "Applying Knowledge of Conventions: use editing, proofreading, and publishing skills and strategies, and knowledge of language conventions, to correct errors, refine expression, and present their work effectively;",
                    "Reflecting on Writing Skills and Strategies: reflect on and identify their strengths as writers, areas for improvement, and the strategies they found most helpful at different stages in the writing process.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "This course has been designed to scaffold literacy skills. Students will be reintroduced to literacy and the building blocks of language: grammar. Reviewing basic concepts will empower the students to become successful in the succeeding units. They are given many opportunities to practice their reading and writing skills through interactive lessons. ",
        lst: [
            "Throughout the course, teachers will provide constructive, prompt, and clear feedback to support individual students' needs as they reflect on their performance.",
            "Students will be exposed to a variety of texts. They should demonstrate an understanding of each type. Through modelling, students will eventually apply the knowledge through different compositions",
            "Students are encouraged to take the course at their own pace, and their teacher facilitates this by leading the journey. The objective is for them to become independent, critical and skilled learners",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Literacy_Skills: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}