export const AWQ2O = {
    title: "Photography",
    description: {
        code: "AWQ2O",
        department: "Arts",
        type: "Open",
        credit: "1.0",
        grade: "9/10",
        prerequisite: "None",
        document: "Revised Visual Arts Curriculum, 2010 ",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/arts910curr2010.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course gives students a solid foundation in the fundamentals of black and white photography. Students will learn to use a 35mm camera, develop film and print photographs. In addition, students will learn the basics of digital photography, taking digital photos and doing basic editing in Adobe Photoshop. Through technical skill building and creative problem solving, students will learn how to use the elements and principles of composition to create meaningful images. The emphasis will be on the process of creative exploration and experimentation. In addition to the technical learning and creative expression involved in this course, students will also learn about the history of photography, its development and its many and varied purposes and forms. Students will also learn to analyze, interpret and evaluate their photographs and those of other photographers.",
    },
    outline: {
        title: "AWQ2O Photography- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Photography Basics",
                hour: "20 Hours",
                content: "In this unit, students will become acquainted with fundamental photography techniques and learn about the history of cameras. Students will improve their observation abilities and comprehend the connections between the camera and the eye. Understanding fundamental photographic history and process will be mentioned and realised via the examination of fundamental methods such as photograms and pinhole cameras.",
            },
            {
                title: "Unit Two: Photographic Composition",
                hour: "20 Hours",
                contetn: "In this unit, students will investigate photographic elements and apply them to their own photographs, as well as be exposed to contemporary photography, other artists, and peer work. Students will begin to open up and share their own personal perspectives and thoughts through creative expressions as they grow more receptive to the concepts in photography and art made by others.",
            },
            {
                title: "Unit Three: Introduction to Photo Editing",
                hour: "20 Hours",
                content: "In this unit, students will explore and examine the basic concepts and underlying principles of photo editing specifically using Adobe Photoshop. They will choose different effects and apply it to their images. Students will be introduced to different learning activities preparing them to be skillful before they take the plunge into the world of work as a photo editor and to feel more confident while providing support where needed.",
            },
            {
                title: "Unit Four: Photography Careers",
                hour: "20 Hours",
                content: "They will investigate the world of work as photographers and demonstrate what they have learned. Students will be able to propose their own photographic career and use their creative and critical thinking skills, as well as previously mastered artistic approaches.",
            },
            {
                title: "RST",
                hour: "10 Hours",
                content: "The RST is 30% of the Final Mark",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "AWQ2O: Photography ,Grade 10",
        content: [
            {
                title: "Creating And Presenting",
                overall: [
                    "The Creative Process: apply the stages of the creative process when performing notated and/or improvised music and composing and/or arranging music; ",
                    "The Elements of Music: apply elements of music when performing notated and improvised music and composing and/or arranging music;",
                    "Techniques and Technologies: use a variety of techniques and technological tools when performing music and composing and/or arranging music.",
                ],
            },
            {
                title: "Reflecting, Responding, And Andanalysing",
                overall: [
                    "The Critical Analysis Process: use the critical analysis process when responding to, analysing, reflecting on, and interpreting music;",
                    "Music and Society: demonstrate an understanding of how traditional, commercial, and art music reflect the society in which they were created and how they have affected communities or cultures;",
                    "Skills and Personal Growth: demonstrate an understanding of how performing, creating, and critically analysing music has affected their skills and personal development; ",
                    "Connections Beyond the Classroom: identify and describe various opportunities for continued engagement in music.",
                ]
            },
            {
                title: "Foundations",
                overall: [
                    "Theory and Terminology: demonstrate an understanding of music theory with respect to concepts of notation and the elements and other components of music, and use appropriate terminology relating to them; ",
                    "Characteristics and Development of Music: demonstrate an understanding of the history of some musical forms and of characteristics of types of music from around the world; ",
                    "Conventions and Responsible Practices: demonstrate an understanding of responsible practices and performance conventions relating to music.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "The instructor will give various chances for students to build skills of inquiry, problem solving, and communication as they research and learn essential ideas of photography through a range of instructional methodologies. The integration of critical thinking and critical inquiry skills will give a great tool for developing their own artwork and demonstrating their abilities in the workplace",
        p2: "This course will focus on developing students’ critical thinking and inquiry skills, in order to develop their appreciation of, and engagement with, the multilayered subject matter. The goal of this focus on critical thinking and inquiry skills is to support students in attempting to reach beyond superficial conclusions and move towards deeper understanding.",
        lst: [
            "The inquiry process consists of formulating questions; gathering and organizing evidence; interpreting and analyzing evidence; evaluating evidence and drawing conclusions; and communicating findings.",
            "Critical thinking then applies the steps of the inquiry process toward examinations of opinions, values, biases and meanings.",
            "Teachers will support students’ development of these skills by modeling in the classroom, providing ongoing and varied opportunities to exercise.",
            "Assessment and evaluation will focus on students’ effective use of critical thinking skills, and not solely on a traditional “product”. ",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Photography: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}