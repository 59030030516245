export const SNC2D = {
    title: "Science",
    description: {
        code: "SNC2D",
        department: "Science",
        type: "Academic",
        credit: "1.0",
        grade: "10",
        prerequisite: "SNC1D, Science, Grade 9, Academic or SNC1P, Science, Grade 9, Applied",
        document: "Science, The Ontario Curriculum, Grades 9 and 10, 2008",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/science910_2008.pdf",
        developer: "KAI School",
        date: 2020 - 2021,
        content: "This course enables students to enhance their understanding of concepts in biology, chemistry, earth and space science, and physics, and of the interrelationships between science, technology, society, and the environment. Students are also given opportunities to further develop their scientific investigation skills. Students will plan and conduct investigations and develop their understanding of scientific theories related to the connections between cells and systems in animals and plants; chemical reactions, with a particular focus on acid/base reactions; forces that affect climate and climate change; and the interaction of light and matter.",
    },
    outline: {
        title: "SNC2D Science- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Introduction to Scientific Investigation Skills and Career Exploration",
                hour: "20 Hours",
                content: "Students will learn to demonstrate scientific investigation skills related to both inquiry and research in the four areas of skills. It helps them to identify and describe a variety of careers related to the fields of science under study, and identify scientists, including Canadians, who have made contributions to those fields.",
            },
            {
                title: "Unit Two: Biology",
                hour: "19 Hours",
                contetn: "Students will demonstrate an understanding of the predictable ways in which chemicals react. They will also explore how chemical reactions may have a negative impact on the environment, but they can also be used to address environmental challenges.",
            },
            {
                title: "Unit Three: Chemical Reactions and Their Properties",
                hour: "20 Hours",
                content: "In this unit, students will investigate the many financial aid options accessible to company owners and individuals, as well as methods for calculating prospective costs and tracking the flow of money. They will also look into ways for companies to save, invest, and safeguard their money.",
            },
            {
                title: "Unit Four: Climate Change",
                hour: "21 Hours",
                content: "Students will demonstrate an understanding that Earth’s climate is dynamic and is the result of interacting systems and processes.  They will explore how global climate change is influenced by both natural and human factors.  Students will also investigate the variety of ways climate change affects living things and natural systems.  Finally, they will assess the impact of human activity on climate change and to identify effective courses of action to reduce this impact.",
            },
            {
                title: "Unit Five: Light and Geometric Optics",
                hour: "21 Hours",
                content: "Students will demonstrate an understanding of the characteristics and properties of light that can be manipulated with mirrors and lenses for a range of uses.  They will also explore the ways in which society has benefited from the development of a range of optical devices and technologies. ",
            },
            {
                title: "RST",
                hour: "6 Hours",
                content: "This is a proctored assessment worth 30% of your final grade",
            },
            {
                title: "Final Exam",
                hour: "3 Hours",
                content: "This is a proctored assessment worth 30% of your final grade",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "SNC2D: Science ,Grade 10",
        content: [
            {
                title: "Scientific Investigation Skills And Career Exploration",
                overall: [
                    "demonstrate scientific investigation skills (related to both inquiry and research) in the four areas of skills (initiating and planning, performing and recording, analysing and interpreting, and communicating); ",
                    "identify and describe a variety of careers related to the fields of science under study, and identify scientists, including Canadians, who have made contributions to those fields.",
                ],
            },
            {
                title: "Biology: Tissues, Organs, And Systems Of Living Things",
                overall: [
                    "evaluate the importance of medical and other technological developments related to systems biology, and analyse their societal and ethical implications; ",
                    "investigate cell division, cell specialization, organs, and systems in animals and plants, using research and inquiry skills, including various laboratory techniques",
                    "demonstrate an understanding of the hierarchical organization of cells, from tissues, to organs, to systems in animals and plants",
                ]
            },
            {
                title: "Chemistry: Atoms, Elements, And Compounds",
                overall: [
                    "analyse a variety of safety and environmental issues associated with chemical reactions, including the ways in which chemical reactions can be applied to address environmental challenges; ",
                    "investigate, through inquiry, the characteristics of chemical reactions;",
                    "demonstrate an understanding of the general principles of chemical reactions, and various ways to represent them.",
                ]
            },
            {
                title: "Earth And Space Science: The Study Of The Universe",
                overall: [
                    "analyse some of the effects of climate change around the world, and assess the effectiveness of initiatives that attempt to address the issue of climate change; ",
                    "investigate various natural and human factors that influence Earth’s climate and climate change;",
                    "demonstrate an understanding of natural and human factors, including the greenhouse effect, that influence Earth’s climate and contribute to climate change.",
                ]
            },
            {
                title: "Physics: Light And Geometric Optics",
                overall: [
                    "evaluate the effectiveness of technological devices and procedures designed to make use of light, and assess their social benefits;",
                    "investigate, through inquiry, the properties of light, and predict its behaviour, particularly with respect to reflection in plane and curved mirrors and refraction in converging lenses;",
                    "demonstrate an understanding of various characteristics and properties of light, particularly with respect to reflection in mirrors and reflection and refraction in lenses.",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "This course’s main goal is to assist students in learning science and applying their knowledge and skills. Language is used effectively, confidently, and flexibly by course writers. Effective instructional strategies and learning activities build on students’ prior knowledge, stimulate their attention, and provide opportunities for meaningful practice. Students will be more interested if they can understand the link between the scientific concepts they are studying and how they are used in the world around them and in real-life situations. ",
        p2: "",
        lst: [
            "Teachers will design activities and challenges that actively involve students in investigations that respect the ideas and talents they bring to the table while also improving their conceptual understandings and vital abilities. Students will be able to employ scientific reasoning throughout their life if they understand huge ideas.",
            "Contextualized teaching and learning also gives teachers valuable insights into their students’ thinking, conceptual grasp, and ability to reflect on their work. This knowledge enables teachers to provide assistance to students in order to improve their learning. To meet a diversity of learning styles, interests, and skill levels, a number of instructional tactics are employed to give learning opportunities. ",
            "Incorporating interactive tools and recorded video discussion that help students stay focused and engaged in the class. Additionally, it aids students in mastering the science concept.",
            "Virtual laboratories, simulations, and journal papers have all been used. Virtual laboratories, such as gizmos and labster, are one of the most effective ways to connect students to the actual world. Because of the dangers and limited face-to-face connection, students are able to undertake numerous experiments that are difficult to perform in traditional laboratories",
            "Virtual clues can easily supplement auditory information, allowing students to better engage with ideas.",
            "The multi-sensory experiences improve their understanding and memorization skills. Drawings, diagrams, and image analysis are used to aid theory, as well as laying up instances to demonstrate its application side",
            "The sequence of lab procedures can be better taught using pictures with words.",
            "Scaffolding on their laboratory activities and unit projects provide the students support level along the process of learning given by the teacher.  Teacher feedback at each level enables students to improve both style and content in their written pieces.",
            "Students who are not challenged are both recipes for a lack of learning, so the quality and quantity of homework should be evaluated. Giving immediate feedback is the greatest way to keep track of students’’ progress.",
            "Conduct of journal researches or case studies on some parts of the course enhance students’ analyzation, scientific and reasoning skills.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Science: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}