import WordChanger from "../ToolComp/WordChanger";

export default function Hero() {
    const slogan = ["Global School", "Educatinal Innovation", "Cultural Diversity", "Personalized Learning"];
    return (
        <div id="hero">
            <div className="hero-container">
                <div className="bg-container" style={{ visibility: "visible", animationName: "fadeIn" }} >
                    <div className="hero-logo">
                        <img src="/img/KAIS.png" alt="kai" />
                    </div>
                    <h1>KAI School</h1>
                    <h2>
                        <WordChanger words={slogan} />
                    </h2>
                    <div className="actions">
                        <a href="/#/admission/application" className="btn-get-started">Apply Today</a>
                        <a href="/#/Contact/contactus" class="btn-contact">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    )
}