import React from "react";
import "../../styles/CompStyle/Footer.css"
export default function Footer() {
    return (
        <div className="Footer">
            <p className="copyright">
                &copy; 2023 KAI School. All rights reserved.
            </p>
        </div>
    );
}