import Divider from "../../components/ToolComp/divider";
import Title from "../../components/ToolComp/title";

export default function CourseCalender() {
    return (
        <div className="courseCalender main-section">
            <Title title="Course Calender" />
            <div className="school section">
                <h2>School</h2>
                <Divider />
                <div className='divider' />
                <div className='subsection'>
                <h3>1.  School Goal and Poilosophy</h3>
                <p>The mission of KAI School is to empower every student by harnessing technology to facilitate academic achievement. Our endeavor is to encourage students to reach their maximum academic potential, tailored to their individual learning styles and pace. Extensive research highlights the significant significance and worth of finishing secondary education, not solely for the individual but also for the broader community and stakeholders involved. KAI School remains dedicated to collaborating with students and parents alike, ensuring all learners accomplish their objectives towards obtaining an Ontario Secondary School Diploma (OSSD).</p>
                </div>
                <div className='subsection'>
                <h3>2.  School Commitment</h3>
                <p>Every adolescent possesses distinct interests, objectives, and capabilities. Nevertheless, each student deserves an equal chance to thrive and obtain a high school diploma. Bill 52, known as The Education Amendment Act, Learning to Age 18, 2006, stipulates that teenagers must stay in school until they reach the age of 18. Nonetheless, every student has their individual learning style.This is precisely where KAI School can make a substantial impact on numerous lives. KAI acknowledges the significance of effectively finishing secondary education and presents students with an alternative avenue to attain this accomplishment.</p>
                </div>
                <div className='subsection'>
                <h3>3.	Vision and Mission</h3>
                <p>
                        <strong>Vision:</strong><br />
                    At KAI School, we envision a dynamic and inclusive learning community that empowers students to excel academically, embrace their unique identities, and become responsible global citizens. Through innovative education and personalized guidance, we aspire to cultivate a passion for lifelong learning and inspire students to contribute positively to society.
                    </p>
                    <p>
                        <strong>Mission:</strong><br />
                    Our mission at KAI School is to provide a nurturing and challenging educational environment that fosters intellectual curiosity, critical thinking, and holistic growth. We are committed to delivering a well-rounded education that balances academic excellence with character development. By embracing diversity, promoting collaboration, and nurturing creativity, we aim to equip our students with the skills, values, and resilience needed to navigate an ever-changing world.

                </p>
                </div>
                <div className='subsection'>
                <h3>4.	School Organization</h3>
                <p>
                    KAI School operates under a continuous entry and exit model, eliminating specific start and end dates. Therefore, it is the students' responsibility to communicate their intended completion date to the teacher and adhere to that timeline within reasonable bounds. KAI School takes pride in its year-round support for student learning. Consequently, predefined reporting dates are not part of our structure.
                    </p>
                    <p>
                    Upon reaching the halfway point of the course, students will receive a Provincial midterm report card. Upon completing the course, the student will receive a final report card, which can be submitted to day schools, post-secondary institutions, or application centers if applicable.

                </p>
                </div>
                <div className='subsection'>
                <h3>5.	Rights and Responsibilities of Students, Parents, and Staff</h3>
                <p>
                        At KAI School, we place great importance on the rights and responsibilities of students, parents, and staff. By fostering a collaborative and respectful environment, we ensure the success and well-being of everyone in our educational community.
                    </p>
                    <h4>Rights and Responsibilities of Students:</h4>

                    <p><strong>Rights:</strong></p>
                    <ol>
                        <li><strong>Knowledge of Assessment Methods:</strong> Students have the right to be informed about the assessment methods used to determine their course grades, including rubrics or marking schemes.</li>
                        <li><strong>Timely Feedback:</strong> Students have the right to receive timely feedback on their work quality and performance.</li>
                        <li><strong>Access to Information:</strong> Students have the right to be informed about the Ministry's assessment and evaluation policy and the consequences of academic dishonesty.</li>
                        <li><strong>Evidence of Achievement:</strong> Students have the right to provide evidence of their achievement through honest and authentic work.</li>
                    </ol>

                    <p><strong>Responsibilities:</strong></p>
                    <ol>
                        <li><strong>Academic Honesty:</strong> Students are responsible for maintaining academic honesty by producing their own work and appropriately citing references.</li>
                        <li><strong>Quality Effort:</strong> Students must work to the best of their ability and submit assignments on time.</li>
                        <li><strong>Active Participation:</strong> Students should actively participate in classes and the learning process, seeking help when needed.</li>
                        <li><strong>Meeting Timelines:</strong> If difficulties arise, students are responsible for communicating with teachers about timeline challenges and completing missed work within established timeframes.</li>
                        <li><strong>Communication with Parents:</strong> Students should keep their parents informed about assessment methods and due dates.</li>
                    </ol>

                    <p><strong>Expectations of Students:</strong></p>
                    <ol>
                        <li><strong>Active Learning:</strong> Students are expected to be engaged participants in their learning process.</li>
                        <li><strong>Timely Submission:</strong> Students are expected to submit assignments on time.</li>
                        <li><strong>Respecting Deadlines:</strong> Students are expected to adhere to set deadlines for work submission.</li>
                        <li><strong>Professional Judgment:</strong> Teachers' professional judgment will determine student achievement assessments.</li>
                        <li><strong>Academic Integrity:</strong> Students are expected to uphold academic integrity by producing original work and citing sources properly.</li>
                    </ol>

                    <h4>Rights and Responsibilities of Parents:</h4>

                    <p><strong>Rights:</strong></p>
                    <ol>
                        <li><strong>Access to Information:</strong> Parents have the right to be informed about course requirements, assessment methods, and timelines.</li>
                        <li><strong>Progress Updates:</strong> Parents have the right to receive information about their child's performance and access to their child's course progress.</li>
                        <li><strong>Support and Communication:</strong> Parents can initiate communication with teachers and work collaboratively to support their child's improvement.</li>
                    </ol>

                    <p><strong>Responsibilities:</strong></p>
                    <ol>
                        <li><strong>Supporting Learning:</strong> Parents are responsible for supporting their child's learning, monitoring progress, and collaborating with the school.</li>
                        <li><strong>Communication:</strong> Parents should maintain regular communication with the school and teachers and contribute to their child's success.</li>
                    </ol>

                    <p><strong>Academic Honesty and Integrity:</strong></p>
                    <p>
                        Students are expected to maintain academic honesty, ensuring that all tests, exams, and assignments are their own original work. Cheating, plagiarism, and other forms of academic dishonesty are strictly prohibited. Academic dishonesty includes:
                    </p>
                    <ul>
                        <li>Using ideas, thoughts, or direct quotations from others without proper acknowledgment.</li>
                        <li>Submitting work done by others as one's own.</li>
                        <li>Copying or allowing others to copy assignments.</li>
                        <li>Providing unauthorized assistance during exams.</li>
                    </ul>
                    <p>
                        Acts of academic dishonesty will result in consequences, including a zero mark for the assignment and potential further actions, based on factors such as grade level, maturity, and circumstances.
                    </p>

                    <p><strong>Use of Learning Management System (LMS):</strong></p>
                    <p>
                        The LMS is solely for educational purposes, and any inappropriate use, including criminal, obscene, commercial, or illegal purposes, is prohibited. The administration has the right to review student work for appropriateness. Inappropriate LMS use may result in consequences, including suspension or removal from the program.
                    </p>
                    <p>
                        Students are reminded to:
                    </p>
                    <ul>
                        <li>Safeguard their passwords.</li>
                        <li>Report suspicious incidents to teachers.</li>
                        <li>Access only content intended for student use.</li>
                    </ul>

                    <p>
                        By adhering to these rights and responsibilities, students, parents, and staff contribute to a respectful, transparent, and academically honest learning environment at KAI School.
                    </p>
                </div>

                <div className='subsection'>
                <h3>6.	**Student Achievements and Assessment Policy:**</h3>
                <p>
                    The Assessment and Evaluation Policy of KAI School aligns with Ministry guidelines and embodies the school's vision, which underscores that assessment primarily serves to enhance student learning. Assessment is the systematic process of collecting information to accurately gauge a student's progress in meeting the curriculum expectations of a subject or course. This process of enhancing student learning is encompassed by both "assessment for learning" and "assessment as learning." Within "assessment for learning," educators provide descriptive feedback and guidance to aid students in improvement. Meanwhile, "assessment as learning" involves helping students cultivate independence and the ability to set goals, monitor progress, determine next steps, and reflect on their learning journey. Continuous pre-assessment and formative assessment will provide constructive feedback on student advancement, aiming to enhance overall performance.
                    </p>


                    <p>
                    Summative assessments will determine the final grade: 70% stems from cumulative summative evaluations conducted throughout the semester, while 30% arises from end-of-course assessments in the final third of the term. Depending on individual curriculum policies, final assessments may or may not include exams. Course outlines distributed at the course outset will furnish detailed assessment and evaluation information, outlining the percentage allocation for both components. All courses follow this distribution:
                    </p>

                    <ul>
                        <li>Term work: 70% [evaluated across Knowledge and Understanding, Thinking and Investigation, Communication, and Application]</li>
                        <li>Final Summatives: 30% [evaluated across Knowledge and Understanding, Thinking and Investigation, Communication, and Application]</li>
                    </ul>

                    <p>
                    While the framework remains consistent across courses, subject-specific summative tasks may influence the final grade. For detailed information, students are advised to consult the course outline provided in their respective courses.
                    </p>

                    <p>
                    In line with the principle that assessment and evaluation foster learning improvement, students encountering initial difficulty will receive additional opportunities to showcase their learning. The decision to exercise this option lies with the teacher, grounded in professional judgment.
                    </p>

                    <p>
                        <strong>Additional Policies:</strong>
                    </p>

                    <ul>
                        <li>Repeating a Course: A repeated course earns only one credit.</li>
                        <li>Grade 11 and 12 Course Repeats: A student's transcript will display an "R" for the course with the lower mark.</li>
                        <li>Course Transfer: If a student enrolls, doesn't complete an assessment within a week, and requests transfer, it will be granted once, only within the first week of enrollment, and decided by the OVA Principal.</li>
                    </ul>

                    <p>
                        <strong>Reporting and Evaluation:</strong>
                    </p>

                    <p>
                        KAI School employs the Provincial Report Card, Grades 9-12, issued twice during a student's active enrollment. The initial report reflects achievement and learning skills during the initial reporting period, while the final report captures the entire course's achievement and learning skills development.
                    </p>

                    <p>
                        <strong>Learning Skills Assessment:</strong>
                    </p>

                    <p>
                        The following Learning Skills guideline will be employed by online teachers when evaluating students' Learning Skills:
                    </p>
                </div>


                <div className="subsection">
                    <h3>7.	Learning Skills and Habits - Sample Behaviors</h3>
                    <ul>
                        <li>
                            <strong>Responsibility:</strong>
                            <ul>
                                <li>Fulfills obligations and commitments within the learning environment.</li>
                                <li>Submits class work, homework, and assignments punctually, adhering to established timelines.</li>
                                <li>Assumes accountability for behavior and actions.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Organization:</strong>
                            <ul>
                                <li>Develops and adheres to a systematic plan and approach for completing tasks.</li>
                                <li>Sets priorities and manages time effectively to accomplish tasks and attain objectives.</li>
                                <li>Identifies, evaluates, and utilizes information, technology, and resources to fulfill tasks.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Independent Work:</strong>
                            <ul>
                                <li>Autonomously monitors, evaluates, and revises plans to accomplish tasks and meet objectives.</li>
                                <li>Utilizes class time appropriately and efficiently to finalize tasks.</li>
                                <li>Follows instructions with minimal need for supervision.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Collaboration:</strong>
                            <ul>
                                <li>Embraces diverse roles and contributes fairly within a group dynamic.</li>
                                <li>Demonstrates open acceptance of others' ideas, opinions, values, and traditions.</li>
                                <li>Cultivates healthy interpersonal relationships through personal and technology-mediated interactions.</li>
                                <li>Collaborates to resolve conflicts, forge consensus, and achieve collective objectives.</li>
                                <li>Shares information, expertise, and resources, encouraging critical thinking for effective problem-solving and decision-making.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Initiative:</strong>
                            <ul>
                                <li>Seeks out and acts upon fresh ideas and opportunities for learning.</li>
                                <li>Exhibits a willingness to take calculated risks and display innovation.</li>
                                <li>Approaches novel tasks with enthusiasm and a positive mindset.</li>
                                <li>Demonstrates curiosity and active engagement in learning.</li>
                                <li>Advocates appropriately for personal rights and the rights of others.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Self-Regulation:</strong>
                            <ul>
                                <li>Sets personalized goals and consistently monitors progress towards attainment.</li>
                                <li>Seeks clarification or assistance when faced with uncertainty.</li>
                                <li>Engages in critical self-assessment, acknowledging strengths, needs, and interests.</li>
                                <li>Identifies and selects learning opportunities, strategies, and choices in alignment with individual needs and goals.</li>
                                <li>Persists and exerts effort when confronting challenges, demonstrating resilience in the face of difficulties.</li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        These sample behaviors illustrate the Learning Skills and Habits that KAI School values and encourages in our students. These attributes contribute to a constructive and productive learning environment while nurturing essential skills for success in both academic and real-world contexts.
                    </p>
                </div>
                <div className='subsection'>
                <h3>8.	Attendance Policy</h3>
                <p>
                    Consistent and active attendance plays a pivotal role in fostering student success. Regular participation is essential for students to fully engage with their learning and unlock their potential.
                    </p>
                    <p>
                    Teachers will diligently monitor attendance and participation patterns of their students. They will collaborate with students and their parents/guardians to provide support and address any attendance challenges. If persistent attendance issues arise, the administration will be informed. Potential interventions may include communication with students and parents/guardians, establishment of attendance contracts, or in extreme cases, withdrawal from the program.
                    </p>
                    <p>
                    To maintain accurate attendance records, the number of completed lessons will serve as the basis. For instance, if a course comprises 20 lessons and a student completes 16 of them, the attendance register will reflect 80% attendance for the 110-hour credit course. This proportion will be adjusted for mid-semester reporting purposes.

                    Students who fail to complete their courses within a 12-month timeframe will be withdrawn from the course. Reinstatement will only occur if there are substantiated extenuating circumstances supported by appropriate documentation. This policy aims to ensure fairness while maintaining the integrity of the learning process.

                </p>
                </div>
                <div className='subsection'>
                <h3>9.	School's Code of Conduct</h3>
                <p>
                    KAI  School is dedicated to fostering a secure and nurturing educational environment conducive to effective learning. Safeguarding the dignity and self-worth of every individual is of paramount importance and is non-negotiable.
                    </p>
                    <p>
                    All constituents of the KAI  School community, encompassing students, staff, and parents, are expected to uphold respect in all interactions. Any behavior found to compromise the moral character of the learning community, including disrespectful, offensive, abusive, or harassing comments directed at any community member, will not be tolerated. Swift action will be taken by the KAI School Principal to address such instances. Consequences for violating this code of conduct may encompass counseling, parental involvement, suspension, expulsion, and/or involvement of appropriate authorities. Our commitment to sustaining a respectful and supportive learning environment remains unwavering.

                    </p>
                </div>

            </div>
            <div className="diploma section">
                <h2>Diploma and Certificates</h2>
                <Divider />
                <div className='divider' />
                <div className='subsection'>
                <h3>1.	Compulsory Credit Requirements</h3>
                <p>
                    Students interested in earning the OSSD (Ontario Secondary School Diploma) must successfully complete a total of 30 credits; of which 18 are prescribed and 12 credits are optional. In addition, students must also complete 40 hours of Community Involvement Activities and must meet the provincial literacy requirement.
                </p>
                </div>
                <div className='subsection'>
                    <h3>2.	Compulsory Credits Are:</h3>
                    <ul>
                        <li>4 credits in English (1 credit per grade) *</li>
                        <li>3 credits in mathematics (1 credit in Grade 11 or 12)</li>
                        <li>2 credits in science</li>
                        <li>1 credit in Canadian history</li>
                        <li>1 credit in Canadian geography</li>
                        <li>1 credit in the arts</li>
                        <li>1 credit in health and physical education</li>
                        <li>1 credit in French as a second language</li>
                        <li>0.5 credit in career studies</li>
                        <li>0.5 credit in civics</li>
                    </ul>
                </div>
                <div className='subsection'>
                    <h3>3.	Plus one credit from each of the following groups:</h3>
                    <ul>
                        <li>1 additional credit (group 1): additional credit in English, or French as a second language, or a Native language, or a classical or an international language, or social sciences, or Native studies or the humanities, or Canadian and world studies, or guidance and career education, or cooperative education</li>
                        <li>1 additional credit (group 2): additional credit in health and physical education, or the arts, or business studies, or French as a second language, or cooperative education</li>
                        <li>1 additional credit (group 3): additional credit in science (Grade 11 or 12), or technological education, or French as a second language, or computer studies, or cooperative education</li>
                    </ul>
                </div>
                <div className='subsection'>
                    <h3>4.	Optional Credits</h3>
                <p>
                    In addition to the 18 compulsory credits described above, students must also complete an additional 12 Optional Credits.
                </p>
                </div>
                <div className='subsection'>
                <h3>5.	OSSLT</h3>
                    <p>Ontario Secondary School Literacy Test (OSSLT)</p>

                    <p>As an integral component of the Ontario Secondary School Diploma (OSSD) requirements, all graduates are expected to successfully complete the Ontario Secondary School Literacy Test (OSSLT) or its equivalent. To facilitate this process, students are urged to promptly liaise with the school office, ensuring confirmation of their eligibility to participate in the literacy test. It is imperative that these arrangements are finalized by no later than January 31.</p>

                    <p>For students seeking accommodations, special provisions, deferrals, or exemptions, it is essential to communicate such requirements to the principal prior to registering for the literacy test. This proactive approach ensures that appropriate arrangements are made to support every student's unique needs and circumstances.</p>
                </div>
                <div className='subsection'>
                    <h3>6.	Ontario Secondary School Literacy Course (OSSLC)</h3>
                    <p>Students who have been eligible to attempt the Ontario Secondary School Literacy Test (OSSLT) on at least two occasions, and who have not achieved a passing result at least once, are qualified to enroll in the Ontario Secondary School Literacy Course (OSSLC). In unique cases, students who have already fulfilled the graduation literacy requirement might be considered for course enrollment, based on the principal's discretion.</p>

                    <p>The OSSLC curriculum is meticulously crafted to assist students in acquiring and showcasing the vital cross-curricular literacy skills evaluated by the Ontario Secondary School Literacy Test (OSSLT). Successful completion of the course guarantees that students meet the mandatory provincial literacy graduation standard.</p>

                    <p>Throughout the course, students will engage with an array of informative, narrative, and graphic texts. They will craft an assortment of written formats, encompassing summaries, informative paragraphs, opinion pieces, and news reports. Furthermore, students will diligently maintain and curate a portfolio, which will document their reading journeys and house examples of their written work.</p>
                </div>
                <div className='subsection'>
                    <h3>7.	Community Involvement Requirement</h3>
                    <h3>The community involvement requirement</h3>
                    <p>The community involvement requirement serves to cultivate robust connections between students and their communities, fostering enduring and meaningful relationships. Students exclusively enrolled in courses at KAI School, without concurrent enrollment in other schools or programs, are mandated to submit their community involvement record if they haven't met the requisite 40-hour threshold. Please review the following list of approved activities:</p>

                    <h4>Approved Activities:</h4>
                    <p>Eligible Activities encompass volunteer endeavors that can contribute to the accumulation of 40 community involvement hours, an essential graduation requirement. These activities offer services that enhance community welfare and members' well-being and may be undertaken for not-for-profit organizations. If an activity doesn't fall under the approved categories specified by the Board or isn't on the list of ineligible activities, students must secure written approval from the principal before engaging in the activity.</p>

                    <p>Eligible Activities include:</p>
                    <ul>
                        <li>Assisting with school events, School Councils, and activities for children at Elementary Schools.</li>
                        <li>Organizing and leading school activities benefiting the community at Secondary Schools.</li>
                        <li>Volunteering in zoos, animal shelters, farms, and participating in animal care.</li>
                        <li>Assisting galleries, libraries, and community productions in Arts and Culture.</li>
                        <li>Supporting special events, programs, and clerical tasks at Charitable Organizations.</li>
                        <li>Participating in child/youth programs, not-for-profit child care centers, and camps.</li>
                        <li>Assisting with special events, food banks, shelters, and community support services at Community Organizations.</li>
                        <li>Providing assistance to community members in need through Community Service for Individuals.</li>
                        <li>Engaging in environmental projects such as planting, beautification, and recycling.</li>
                        <li>Volunteering in hospitals, hospices, Canadian Blood Services, and donating blood for Health Agencies.</li>
                        <li>Participating in police-sponsored activities for Law Enforcement Agencies.</li>
                        <li>Engaging in activities related to legitimate political organizations and recognized political activities for Political Organizations.</li>
                        <li>Assisting with programs and special events in Religious Organizations.</li>
                        <li>Supporting seniors' residences and providing services for seniors in the community.</li>
                        <li>Coaching, organizing events, and assisting with projects in Sports and Recreation.</li>
                        <li>Expanding community service beyond school hours through Service Focused Club Activities.</li>
                    </ul>

                    <p>Should an eligible activity also fall within the scope of ineligible activities, it will be deemed ineligible.</p>

                    <h4>Activities NOT Approved:</h4>
                    <p>Ineligible activities encompass:</p>
                    <ul>
                        <li>Work for a for-profit organization.</li>
                        <li>Requirements of a class or course in which the student is enrolled.</li>
                        <li>Activities during instructional time on a school day, except during lunch breaks or "spare" periods.</li>
                        <li>Work in logging or mining environments for students under 16 years of age.</li>
                        <li>Work in a factory for students under 15 years of age.</li>
                        <li>Work in a workplace other than a factory for students under 14 years of age, unaccompanied by an adult.</li>
                        <li>Activities typically performed for wages.</li>
                        <li>Operation of vehicles, power tools, or scaffolding.</li>
                        <li>Administration of medication or medical procedures to others.</li>
                        <li>Handling substances classified as "designated substances" under the Occupational Health and Safety Act.</li>
                        <li>Requires knowledge of a provincially regulated trade.</li>
                        <li>Involves banking, securities, jewelry, art, antiques, or valuables.</li>
                        <li>Duties typically performed at home or personally.</li>
                        <li>Court-ordered programs.</li>
                    </ul>

                    <p>To facilitate the community involvement requirement, KAI School will provide students with a record-keeping form to log hours, which will be kept in the student's Ontario Student Record (OSR). It's the students' responsibility to fulfill this requirement independently. For more information or questions regarding community involvement as an OSSD prerequisite, please reach out to the Principal of KAI School. If there's uncertainty about whether a planned community activity fulfills the requirements, students and parents are encouraged to notify the Principal in advance by using the notification of planned community involvement form.</p>
                </div>
                <div className='subsection'>
                    <h3>8.	Prerequisites, Exemptions, Substitutions, and Alternative Studies at KAI School</h3>
                    <p>
                        Prerequisites for Grade 11 and 12 courses are specified in the curriculum policy documents for the various disciplines. These can be viewed on the Ministry of Education website. In most cases, students will only be registered into a course once they have submitted documentation supporting their completion of the prerequisite course. For example, the prerequisite for the Grade 11 university preparation course in Math is the Grade 10 academic course in Math. A student who has completed a course of one type in a particular subject and grade that does not meet a stated prerequisite for a course in the same subject in the next grade but has completed a transfer course is equally eligible to take the requested course providing once again that supporting documentation is forwarded to KAI School. In unique situations, mature students that do not have the prerequisite can apply for an exemption. This must be requested in writing to the Principal of KAI School. The requesting student will have an opportunity to explain their circumstances. An example of such a case would be a student who has been out of any school system for more than one year. If you wish to apply for such consideration, please send an email to admissions@emeraldvalleyacademy.ca.ca outlining the following information as well as forwarding your most recent transcript by email:
                    </p>

                    <ul>
                        <li>Name:</li>
                        <li>Contact information: Address, phone, and email address</li>
                        <li>Date of Birth:</li>
                        <li>Last school attended and Year attended:</li>
                        <li>Reason for Request:</li>
                        <li>Special Circumstances:</li>
                    </ul>

                    <p>
                        KAI School will not make substitutions for compulsory or optional courses; students are expected to complete all compulsory and optional courses as outlined in the Ministry of Education's OSSD graduation requirements.
                    </p>

                    <p>
                        Please download and complete the form below to be considered for prerequisite exemptions.
                    </p>
                </div>
                <div className='subsection'>
                    <h3>9.	Prerequisite Exemption Form</h3>
                <p>
                    Students can earn credits outside of KAI School including ILC, distance education, public or private schools. Students must inform us confirming their enrollment as well as having that institutions forward us directly a record of completion.
                </p>
                </div>

                <div className='subsection'>
                    <h3>10.	Definition Of Types Of Courses</h3>
                    <p>
                        Students in Grades 9 & 10 will take their core courses (English, Mathematics, Science, French, Geography and History) in one of three levels – applied, academic. All elective courses will be taken at the open level.
                    </p>

                    <p>

                        <strong>Academic Level: “D”
                        </strong>
                    </p>

                    <p>
                        Academic courses focus on the essential concepts of the discipline plus additional related concepts. Academic courses develop students’ knowledge and skills by emphasizing theoretical, abstract applications of the essential concepts while incorporating practical applications, as appropriate.
                    </p>

                    <p>
                        <strong>Applied Level: “P”</strong>
                    </p>

                    <p>
                        Applied courses focus on the essential concepts of the discipline. Applied courses develop students’ knowledge and skills by emphasizing practical, concrete applications of the essential concepts while incorporating theoretical applications, as appropriate.
                    </p>

                    <p><strong>Locally Developed Level: “L”</strong>

                    </p>

                    <p>
                        Locally Developed courses focus on the most essential concepts of a discipline. These courses are offered in Mathematics, Science, English, and Canadian History. These courses will provide support for students making the transition to high school by enhancing their skills to allow them to be successful at secondary school.
                    </p>

                    <p>
                        <strong>Open Courses: “O”</strong>
                    </p>

                    <p>
                        Open level courses have one set of expectations for each subject and are appropriate for all students. Open level courses are offered for all non-core subjects and do not have a prescribed post-secondary destination.
                    </p>

                    <p>
                        <strong>Course Level Description for Grade 11 and 12 Courses:</strong>
                    </p>

                    <p>
                        Courses in grades 11 and 12 are offered in levels that are related to a student’s destination after high school – workplace, apprenticeships, college or university. Some courses are offered at the Open level. Most courses are offered at one other following five levels:
                    </p>

                    <p>
                        <strong>University/College Courses: “M”</strong>
                    </p>

                    <p>
                        Courses designed to prepare students for entrance to college and university programs following high school.
                    </p>

                    <p>
                        <strong>College Courses: “C”</strong>
                    </p>

                    <p>
                        Courses designed to prepare students for entrance to college programs following high school.
                    </p>

                    <p>
                        <strong>Workplace Courses: “E”
                        </strong>
                    </p>

                    <p>
                        Courses designed for students planning to enter the workplace directly following high school.
                    </p>

                    <p>
                        <strong>Open Courses: “O”
                        </strong>
                    </p>

                    <p>
                        Courses that are not specific to any particular post-secondary destination are appropriate for all students, and which students may take to meet compulsory or optional requirements.
                    </p>

                    <p>
                        <strong>
                            University Courses: “U”
                        </strong>

                    </p>

                    <p>
                        Courses designed to prepare students for entrance to university programs following high school.
                    </p>
                </div>
                <div className='subsection'>
                    <h3>11.	Course Coding System at KAI School</h3>
                    <p>
                        The secondary education framework in Ontario is designed around the concept of students earning mandatory and optional credits. The course coding system used for all programs offered through KAI School utilizes a 5-character structure that is established and recognized by the Ministry of Education. Here's an example:
                    </p>

                    <p>
                        M(1)C(2)V(3)4(4)U(5)
                    </p>

                    <p>
                        - The first digit signifies the major area of study for the course. For instance, M for Mathematics, S for Science, and E for English.
                    </p>

                    <p>
                        - The second and third digits represent the course descriptor within the subject area. For example, CV for Calculus and Vectors, BI for Biology.
                    </p>

                    <p>
                        - The fourth digit indicates the grade level. 1 denotes grade 9, 2 for grade 10, 3 for grade 11, and 4 for grade 12.
                    </p>

                    <p>
                        - The fifth and last digit is used to indicate the course delivery intensity. For grade 9 and 10, options include D for Academic, P for Applied, and O for Open level courses. For grade 11 and 12, this digit pertains to post-secondary destination: U for University, C for College, and M for courses open to both University and College.
                    </p>

                    <p>
                        KAI School offers a diverse range of D, P, M, C, E, O, and U level courses. These courses fulfill both the mandatory and elective components required for the Ontario Secondary School Diploma (OSSD).
                    </p>
                </div>
                <div className='subsection'>
                    <h3>12.	Description Of Courses at KAI School</h3>
                    <p>
                        Below are abbreviated course descriptions of the courses currently offered at KAI School. For more detailed course profiles, interested individuals are advised to request them from the teacher.
                    </p>

                    <p><strong>Grade 12 Courses</strong></p>

                    <p>
                        <strong>Course Code: BBB4M</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 International Business explores the significance of international business and trade in the global economy. The course delves into the factors impacting success in international markets. Students will learn about effective marketing techniques, distribution strategies, and the management of international business. This course prepares students for post-secondary programs in business, including international business, marketing, and management.<br />
                        <strong>Prerequisite: None</strong>
                    </p>

                    <p>
                        <strong>Course Code: CIA4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Economics prompts students to examine the nature of the competitive global economy. It investigates how individuals and societies can make informed economic decisions. Students will learn about microeconomics and macroeconomics principles, apply economic concepts to interpret economic information, and assess the validity of statistics. They will also analyze marketplace dynamics and present their findings using economic inquiry and communication skills.<br />
                        <strong>Prerequisite: Any Grade 11 or 12 university (U) or university/college (M) preparation course in Social Sciences and Humanities, English, or Canadian and World Studies.</strong>
                    </p>

                    <p>
                        <strong>Course Code: ENG4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 University English aims to enhance literacy, communication, critical thinking, and creative thinking skills needed for success in academia and daily life. Students will analyze challenging literary, informational, and graphic texts from various periods, countries, and cultures. They will create oral, written, and media texts in various forms, focusing on using academic language effectively, selecting appropriate reading strategies, and developing control in writing.<br />
                        <strong>Prerequisite: ENG3U, or NBE3U</strong>
                    </p>

                    <p>
                        <strong>Course Code: LKBDU</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Mandarin (LKBDU) explores themes expressed through poetry, novels, plays, and Chinese history. Students will connect literature and history to real-life issues, exploring how these themes resonate in today's society.<br />
                        <strong>Prerequisite: LKBCU or Assessment Test</strong>
                    </p>

                    <p>
                        <strong>Course Code: MCV4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Calculus & Vectors (MCV4U) builds on students' understanding of functions and rates of change. Students will solve problems involving vectors, lines, planes in three-dimensional space, and derivatives of various functions. This course is suitable for students pursuing careers in science, engineering, economics, and business.<br />
                        <strong>Prerequisite: Proof of completion or enrollment in MHF4U</strong>
                    </p>

                    <p>
                        <strong>Course Code: MDM4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Data Management (MDM4U) expands students' understanding of mathematics as it relates to data management. Students will analyze and organize large amounts of information, solve probability and statistical problems, and carry out a culminating investigation. This course is valuable for students planning to enter business, social sciences, or humanities programs.<br />
                        <strong>Prerequisite: MCR3U or MCF3M</strong>
                    </p>

                    <p>
                        <strong>Course Code: MHF4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Advanced Functions (MHF4U) extends students' experience with functions, investigating properties of various functions, rates of change, and combining functions. This course is intended for students taking university-level calculus, linear algebra, or physics courses.<br />
                        <strong>Prerequisite: MCR3U or MCT4C</strong>
                    </p>

                    <p>
                        <strong>Course Code: OLC4O</strong><br />
                        <strong>Course Description:</strong><br />
                        The Ontario Secondary School Literacy Course (OSSLC) is a full-credit Grade 12 course offered to provide intensive support in achieving the required reading and writing competencies. The course focuses on the reading and writing competencies required by the Ontario Secondary School Literacy Test (OSSLT).<br />
                        <strong>Prerequisite: Students who have been eligible to write the OSSLT at least twice and who have been unsuccessful at least once are eligible to take the course.</strong>
                    </p>

                    <p>
                        <strong>Course Code: SBI4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Biology delves into biological processes in various systems and fields. Students will study theory and conduct investigations across diverse areas of biology.<br />
                        <strong>Prerequisite: SBI3U</strong>
                    </p>

                    <p>
                        <strong>Course Code: SCH4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Chemistry provides a deeper understanding of chemical processes, communication of scientific data, problem-solving, and investigation skills. The course emphasizes the impact of chemistry on daily life and the environment.<br />
                        <strong>Prerequisite: SCH3U</strong>
                    </p>

                    <p>
                        <strong>Course Code: SPH4U</strong><br />
                        <strong>Course Description:</strong><br />
                        Grade 12 Physics deepens understanding of physics concepts and theories. Students explore energy transformations, forces affecting motion, electrical, gravitational, and magnetic fields, electromagnetic radiation, wave nature of light, quantum mechanics, and special relativity.<br />
                        <strong>Prerequisite: SPH3U</strong>
                    </p>
                </div>
                <div className='subsection'>
                    <h3>13.  Ontario Curriculum Policy Documents</h3>
                <p>
                        The courses offered at KAI School have been developed in accordance with the requirements set forth by the Ontario Ministry of Education. Detailed information regarding Ministry course documents and Ontario Curriculum Policy documents can be accessed on the Ministry website at<a href='http://www.edu.gov.on.ca/eng/curriculum/secondary/'>http://www.edu.gov.on.ca/eng/curriculum/secondary/</a> 
                </p>
                </div>
                <div className='subsection'>
                    <h3>14.	Experiential Learning</h3>
                <p>
                    Although recognizing the value of job shadowing and cooperative education for hands-on experiences, KAI School does not currently offer co-op based courses.
                </p>
                </div>
                <div className='subsection'>
                    <h3>15.	 Withdrawing From A Course</h3>
                <p>
                    Withdrawals made within 5 days of the issuance of the first report card from KAI School will result in the mark not being recorded on the Ontario Student Transcript (OST). For withdrawals from Grade 11 or 12 courses after this initial period, a "W" will be entered in the "Credit" column of the OST, along with the mark at the time of withdrawal. Withdrawals from Grade 9 or 10 courses at any time will not be recorded on the OST. In cases of extraordinary circumstances, an "S" may be entered in the "Note" column on the OST.
                </p>
                </div>
                <div className='subsection'>
                    <h3>16.	 Changing Course Type</h3>
                    <p>
                        Students may have the opportunity to change course types in certain situations, subject to specific criteria for each subject area. To discuss such changes, please send an email to admissions@emeraldvalleyacademy.ca outlining the provided information and forwarding your most recent transcript by email:
                    </p>

                    <ul>
                        <li>Name</li>
                        <li>Contact Information: Address, phone, and email address</li>
                        <li>Date of Birth</li>
                        <li>Last school attended and year attended</li>
                        <li>Reason for request</li>
                        <li>Special circumstances</li>
                    </ul>
                </div>
                <div className='subsection'>
                    <h3>17.	A Prior Learning Assessment And Recognition (PLAR)</h3>
                    <p>
                        K-12 students may be eligible to receive a credit without enrolling in a course if they can demonstrate skills and knowledge acquired through prior learning. This request should be made in writing to the Principal of KAI School. The student's request should include the following information, along with the most recent transcript:
                    </p>

                    <ul>
                        <li>Name</li>
                        <li>Contact Information: Address, phone, and email address</li>
                        <li>Date of Birth</li>
                        <li>Last school attended and year attended</li>
                        <li>Reason for request</li>
                        <li>Special circumstances</li>
                    </ul>

                    <p>
                        The Principal will assess the skills required for the requested credit equivalency through an interview.
                    </p>

                    <p>
                        <strong>Prior Learning Assessment And Recognition (PLAR) For Mature Students</strong>
                        <br />
                        Mature students have different PLAR procedures based on their broader life experience. Mature students are those who are at least eighteen years old by December 31 of the school year in which they register for an Ontario secondary school program, who were not enrolled for at least one school year immediately preceding their registration, and who are pursuing an OSSD.
                    </p>
                </div>
                <div className='subsection'>
                    <h3>18.  Other Ways Of Earning Credits</h3>
                <p>
                    Students can earn high school credits outside of KAI School through opportunities like eLearning, ILC, and continuing education courses from public school boards. Registration for these courses requires approval from the guidance department.
                </p>
                </div>
                <div className='subsection'>
                    <h3>19.	 Examination Policies</h3>
                <p>
                    Final exams are typically conducted as paper-pen evaluations at a mutually agreed time, date, and location. Exams are proctored by a suitable adult supervisor to ensure security and integrity. Personal affiliates of the student cannot serve as exam supervisors.
                </p>
                </div>
                <div className='subsection'>
                    <h3>20.	 Ontario Student Record (OSR)</h3>
                <p>
                    The OSR is a record of a student's educational progress in Ontario schools. Information in the OSR is privileged for the use of supervisory officers, the principal, and teachers for instructional improvement. Students and parents of non-adult students must be aware of the OSR's purpose, content, and have access to its information.
                </p>
                </div>
                <div className='subsection'>
                    <h3>21.  Ontario Student Transcript (OST)</h3>
                <p>
                    The OST is an official document containing a list of completed courses in grades 9 through 12. It is stored in the OSR and updated by the school holding the OSR. Students completing courses at KAI School will receive a final report card and a copy will be sent to the OST holding school for transcript updates. Certified copies of the OST can be obtained from the guidance department of the OST holding school. Requests for faxing final marks to the Ontario University Application Centre should be sent to admissions@emeraldvalleyacademy.ca, including authorization, student details, course, final mark, OEN, and OUAC reference number.
                </p>
                </div>

            </div>
        </div>
    )
}