import { IconContext } from "react-icons";
import "../../styles/PageStyle/Contact.css";
import { GiModernCity } from "react-icons/gi";
import { FaTreeCity } from "react-icons/fa6";
import Divider from "../../components/ToolComp/divider";
function Contact() {
    return (
        <div id="contactus" className="contact main-section">

            <div className="content-container">
                <h1>Contact Us</h1>
                <Divider />
            <div className="contact-container">
                    <div className="col1 griditem">
                    <div className="icon-outter">
                        <div className="circle">
                            <div className="circle-inner">
                                    <IconContext.Provider value={{ color: "rgb(184, 134, 11)", size: "6rem" }}>
                                    <GiModernCity />
                                </IconContext.Provider>
                            </div>
                        </div>
                        </div>
                    <p className="office">Toronto Office</p>
                        <div className="content-wrap">

                    <p className="email">
                                <span style={{ color: "rgb(184, 134, 11)", fontWeight: "bold" }}>Email:</span>
                        <a href="mailto:admissions@kaischool.ca" className="email-link">admissions@kaischool.ca</a>
                        </p>
                            <p className="address">
                                <span style={{ color: "rgb(184, 134, 11)", fontWeight: "bold" }}>Address: </span>
                                4750 Yonge St. Toronto, Ontario Canada M2N 5M6
                            </p>
                            <p className="fax">
                                <span style={{ color: "rgb(184, 134, 11)", fontWeight: "bold" }}>Fax/Phone: </span>
                                437-268-6158
                            </p>
                    </div>

                </div>
                    <div className="col2 griditem">
                    <div className="icon-outter">
                        <div className="circle">
                            <div className="circle-inner">
                                    <IconContext.Provider value={{ color: "rgb(184, 134, 11)", size: "6rem" }}>
                                    <FaTreeCity />
                                </IconContext.Provider>
                            </div>
                        </div>
                        </div>
                        <p className="office">Asia Office</p>
                        <div className="content-wrap">

                    <p className="email">
                                <span style={{ color: "rgb(184, 134, 11)", fontWeight: "bold" }}>Email:</span>
                            <a href="mailto:admission@kaiglobaledu.com" className="email-link">admission@kaiglobaledu.com</a>
                    </p>
                    </div>

                </div>
                </div>
            </div>

        </div>
    );
}

export default Contact;