import Divider from "../../components/ToolComp/divider";
import "../../styles/PageStyle/Joinus.css";
export default function JoinUs() {
    return (
        <div id="joinus" className="joinus main-section">
            <div className="joinus-container">
                <div className="bg-container" style={{ visibility: "visible", animationName: "fadeIn" }} >
                </div>
                <div className="wea-logo">
                    <a href="https://wingspanedu.ca/" target="_blank">
                        <img src="/img/wea_logo.jpg" />
                    </a>
                </div>
                <div className="context">
                    <div className="text-container">
                        <h2 className="three-line-text">WE'RE HIRING</h2>
                        <Divider />
                        <p className="three-line-text">send resume to our email:</p>
                        <a className="three-line-text" href="mailto:kai@kaischool.ca">KAI@KAISCHOOL.CA</a>
                    </div>
                </div>

            </div>
        </div>
    );
}
