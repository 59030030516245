export const ESLCourseData = [
    {
        title: "ESLCO",
        description: {
            department: "English as a Second Language, Level 3",
            code: "ESLCO",
            grade: "All",
            type: "Open",
            credit: "1.0",
            prerequisite: "ESLCO, English as a Second Language, Level 3, Open or equivalent",
            document: "English as a Second Language and English Literacy Development, The Ontario Curriculum, Grades 9 to 12, 2007",
            link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/esl912currb.pdf",
            developer: "KAI School",
            date: 2021,
            content: "This course further extends students’ skills in listening, speaking, reading, and writing in English for a variety of everyday and academic purposes. Students will make short classroom oral presentations; read a variety of adapted and original texts in English; and write using a variety of text forms. As well, students will expand their academic vocabulary and their study skills to facilitate their transition to the mainstream school program. This course also introduces students to the rights and responsibilities inherent in Canadian citizenship, and to a variety of current Canadian issues. ",
        },
        outline: {
            title: "English as a Second Language ESLCO-Total Hours(110 Hours)",
            units: [
                {
                    title: "Unit One",
                    hour: "20 hours",
                    content: "In this unit, students will learn about language registers and nonverbal issues in this unit, demonstrate comprehension of audio clips and videos with limited contextual and visual support, learn about the Ontario secondary school system, intonation patterns and sentence stress, demonstrate their understanding of written texts, learn how to use resources to figure out the meaning of unfamiliar words, and learn about the Ontario secondary school system.",
                },
                {
                    title: "Unit Two",
                    hour: "20 hours",
                    contetn: "In this unit, students will demonstrate comprehension of specific information from audio clips, engage in spoken interactions on personal and content-area topics, learn about Canadian history, geography, communities, and issues, demonstrate knowledge of Canadian Citizenship, levels of government, and rights and freedoms in writing an email, demonstrate knowledge of greetings, closings, and text forms in writing an email, and determine the meaning of unfamiliar words using knowledge of patterns in this unit.",
                },
                {
                    title: "Unit Three",
                    hour: "20 hours",
                    content: "In this unit, students will use a variety of conversational expressions to negotiate spoken interactions, identify specific features and/or sections of content-area texts and use them to locate information and aid comprehension, locate information on classroom topics from appropriate research materials selected and acknowledge their sources, write complex texts to express ideas and feelings on personal topics using a variety of forms, and write more complex texts to communicate ideas and feelings on personal topics.",
                },
                {
                    title: "Unit Four",
                    hour: "20 hours",
                    content: "In this unit, students will compare and contrast the traditions and behavioural norms of a variety of cultural communities in Canada, including Aboriginal communities; present ideas and information orally for academic purposes in supported situations; extract information from a variety of sources and organize it using appropriate outlines and graphic organizers, and write an informative paragraph to convey a message.",
                },
                {
                    title: "Unit Five",
                    hour: "20 hours",
                    content: "In this unit, learn about the five major literary elements in literature, literary devices, media text, advertising techniques, how to identify the dependability of different sources of information, how to construct media texts, and how to publish a final product in this course.",
                },
                {
                    title: "Rich Task Summative 20%",
                    hour: "8 hours",
                    content: "This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through not only the product but through conversation and observation.",
                },
                {
                    title: "Final Exam 10%",
                    hour: "2 hours",
                    content: "This is a proctored exam worth 30% of your final grade.",
                },
            ],
            note1: "This course is entirely online and does not require or rely on any textbook.",
            note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

        },
        expectations: {
            title: "ESLCO English as a Second LanguageESL Level 3 Open",
            content: [
                {
                    title: "Listening and Speaking",
                    overall: [
                        "demonstrate the ability to understand, interpret, and evaluate spoken English for a variety of purposes",
                        "use speaking skills and strategies to communicate in English for a variety of classroom and social purposes",
                        "use correctly the language structures appropriate for this level to communicate orally in English.",
                    ]
                },
                {
                    title: "Reading",
                    overall: [
                        "use a variety of reading strategies throughout the reading process to extract meaning from texts;",
                        "use a variety of strategies to build vocabulary;",
                        "locate and extract relevant information from written and graphic texts for a variety of purposes.",
                    ]
                },
                {
                    title: "Writing",
                    overall: [
                        "write in a variety of forms for different purposes and audiences;",
                        "organize ideas coherently in writing;",
                        "use correctly the conventions of written English appropriate for this level, including grammar,usage, spelling, and punctuation;",
                    ]
                },
                {
                    title: "Socio-Cultural Competence And Media Literacy",
                    overall: [
                        "demonstrate the ability to understand, interpret, and evaluate spoken English for a variety of purposes;",
                        "use speaking skills and strategies to communicate in English for a variety of classroom and social purposes;",
                        "use correctly the language structures appropriate for this level to communicate orally in English.",
                    ]
                }
            ]
        },
        assessment: {
            content: "",
            tab: [
                {
                    title: "Assessment For Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment For Learning takes place in preparation for course or unit learning ",
                },
                {
                    title: "Assessment As Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment As Learning takes place during or while learning",
                },
                {
                    title: "Assessment Of Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment Of Learning takes place after learning.",
                }
            ],
        },
        strategy: {
            p1: "Students are exposed to a variety of genres throughout the course and develop skills to analyze and evaluate the effectiveness of texts which may include poems, short stories, novels, non-fiction texts, plays, videos, and songs or other media texts from a wide range of cultures and time periods. Students identify and use various strategies including building vocabulary, learning to understand and use features and organization of texts, and developing knowledge of conventions. Throughout the course, students develop into stronger readers, writers, and oral communicators while making connections to the workplace and international events.",
            p2: "Teachers differentiate instruction to meet the diverse learning needs of students. Instructors also use electronic stimuli including Discussion Boards, Google Docs, Skype, Google Hangout and Screencastify to assist students in reflecting on their learning, and in setting goals for improvement in key areas while developing 21st century skills. These tools facilitate and support the editing and revising process for students as they create texts for different audiences and purposes.",
            lst: [
                "Identifying and developing skills and strategies – through modeling of effective skills, students learn to choose and utilize varied techniques to become effective readers, writers, and oral communicators.",
                "Communicating – several opportunities are provided for students to write and communicate orally.",
                "Generating ideas and topics – teachers encourage students to design their own approaches to the material by maintaining frequent (often daily) online communication with students, by allowing some freedom in how students respond to topics and questions, and by encouraging students’ independent thinking through discussion posts.",
                "Researching – various approaches to researching are practised. Students learn how to cite sources and provide a works cited page at the end of longer assignments using MLA formatting.",
                "Thinking critically – students learn to critically analyze texts and to use implied and stated evidence from texts to support their analyses. Students use their critical thinking skills to identify perspectives in texts, including biases that may be present.",
                "Producing published work and making presentations – students engage in the editing and revising process, including self-revision, peer revision, and teacher revision all of which strengthen texts with the aim to publish or present student work.",
                "Reflecting – through the ePortfolio and other elements of the course, students reflect on the learning process, focus on areas for improvement, and make extensions between course content and their personal experiences.",
            ]
        },
        evaluation: {
            title: "Assessment and Evaluation and Reporting Strategies of Student Performance",
            p1: "Our theory of assessment and evaluation follows the Ministry of Education’s Growing Success document, and it is our firm belief that doing so is in the best interests of students. We seek to design assessment in such a way as to make it possible to gather and show evidence of learning in a variety of ways to gradually release responsibility to the students and to give multiple and varied opportunities to reflect on learning and receive detailed feedback.",
            p2: "Growing Success articulates the vision the Ministry has for the purpose and structure of assessment and evaluation techniques. There are seven fundamental principles that ensure best practices and procedures of assessment and evaluation by KAI School global school teachers. KAI School assessments and evaluations,",
            lst: [
                "are fair, transparent, and equitable for all students;",
                "support all students, including those with special education needs, those who are learning the language of instruction (English or French), and those who are First Nation, Métis, or Inuit;",
                "are carefully planned to relate to the curriculum expectations and learning goals and, as much as possible, to the interests, learning styles and preferences, needs, and experiences of all students;",
                "are communicated clearly to students and parents at the beginning of the course and at other points throughout the school year or course;",
                "are ongoing, varied in nature, and administered over a period of time to provide multiple opportunities for students to demonstrate the full range of their learning;",
                "provide ongoing descriptive feedback that is clear, specific, meaningful, and timely to support improved learning and achievement;",
                "develop students’ self-assessment skills to enable them to assess their own learning, set specific goals, and plan next steps for their learning.",
            ],
            p3: "For a full explanation, please refer to Growing Success.",
            link: "http://www.edu.gov.on.ca/eng/policyfunding/growsuccess.pdf",
        },
        grade: {
            p1: "The evaluation for this course is based on the student’s achievement of curriculum expectations and the demonstrated skills required for effective learning. The final percentage grade represents the quality of the student’s overall achievement of the expectations for the course and reflects the corresponding level of achievement as described in the achievement chart for the discipline. A credit is granted and recorded for this course if the student’s grade is 50% or higher. The final grade will be determined as follows:",
            lst: [
                "70% of the grade will be based upon evaluations conducted throughout the course. This portion of the grade will reflect the student’s most consistent level of achievement throughout the course, although special consideration will be given to more recent evidence of achievement.",
                "30% of the grade will be based on final evaluations administered at the end of the course. The final assessment may be a final exam, a final project, or a combination of both an exam and a project.",
            ],
        },
        report: {
            p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
            p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
            p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
        },
        consideration: {
            p1: "Teachers who are planning a program in English must take into account considerations in a number of important areas. Essential information that pertains to all disciplines is provided in the companion piece to this document, The Ontario Curriculum, Grades 9 to 12: Program Planning and Assessment, 2000. The areas of concern to all teachers that are outlined here include the following:",
            lst: [
                "types of secondary school courses",
                "education for exceptional students",
                "the role of technology in the curriculum",
                "English as a second language (ESL) and English literacy development (ELD)",
                "career education",
                "cooperative education and other workplace experiences",
                "health and safety",
            ],
            technology: {
                p1: "Information and communications technologies (ICT) provide a range of tools that can significantly extend and enrich teachers’ instructional strategies and support students’ language learning. ICT tools include multimedia resources, databases, Internet websites, digital cameras, and word-processing programs. Tools such as these can help students to collect, organize, and sort the data they gather and to write, edit, and present reports on their findings.",
            },
            exceptional: {
                p1: "In planning courses, teachers should take into account the needs of exceptional students as set out in their Individual Education Plan. English courses reflect the creative part of our literary world, which offers a vast array of opportunities for exceptional students. Students who use alternative techniques for communication may find a venue for their talents as writers. Just as English responds to the needs and demands of the greater world of work, English courses are largely shaped by the needs and demands of students who will all eventually end up in this greater world.",
            },
            development: {
                title: "English As a Second Language and English Literacy Development",
                title2: "(ESL/ELD)",
                p1: "With exposure to the English language in a supportive learning environment, most young children will develop oral fluency quite quickly, making connections between concepts and skills acquired in their first language and similar concepts and skills presented in English. However, oral fluency is not a good indicator of a student’s knowledge of vocabulary or sentence structure, reading comprehension, or other aspects of language proficiency that play an important role in literacy development and academic success. Research has shown that it takes five to seven years for most English language learners to catch up to their English-speaking peers in their ability to use English for academic purposes. Moreover, the older the children are when they arrive, the greater the language knowledge and skills that they have to catch up on, and the more direct support they require from their teachers. Responsibility for students’ English-language development is shared by the course teacher, the ESL/ELD teacher (where available), and other school staff. Volunteers and peers may also be helpful in supporting English language learners in the language classroom. Teachers must adapt the instructional program in order to facilitate the success of these students in their classrooms. Appropriate adaptations include:",
                lst: [
                    "modification of some or all of the subject expectations so that they are challenging but attainable for the learner at his or her present level of English proficiency, given the necessary support from the teacher;",
                    "use of a variety of instructional strategies (e.g., extensive use of visual cues, graphic organizers, scaffolding; previewing of textbooks, pre-teaching of key vocabulary; peer tutoring; strategic use of students’ first languages);",
                    "use of a variety of learning resources (e.g., visual material, simplified text, bilingual dictionaries, and materials that reflect cultural diversity);",
                    "use of assessment accommodations (e.g., granting of extra time; use of oral interviews, demonstrations or visual representations, or tasks requiring completion of graphic organizers or close sentences instead of essay questions and other assessment tasks that depend heavily on proficiency in English).",
                ],
                note: "When learning expectations in any course are modified for an English language learner (whether the student is enrolled in an ESL or ELD course or not), this information must be clearly indicated on the student’s report card.",
                p2: "This course can provide a wide range of options to address the needs of ESL/ELD students. Detailed analysis of the components of sentences aid ESL students in mastering the English language and all of its idiosyncrasies. In addition, since all occupations require employees with a wide range of English skills and abilities, many students will learn how their backgrounds and language skills can contribute to their success in the larger world.",
                p3: "Antidiscrimination Education in the English Program Learning resources that reflect the broad range of students’ interests, backgrounds, cultures, and experiences are an important aspect of an inclusive English program. In such a program, learning materials involve protagonists of both sexes from a wide variety of backgrounds.",
                p4: "Literacy, Mathematical Literacy, and Inquiry/Research Skills Literacy, mathematical literacy, and inquiry/research skills are critical to students’ success in all subjects of the curriculum and in all areas of their lives. The acquisition and development of literacy skills is clearly the focus of the English curriculum,",
                tab: [
                    {
                        title: "Career Education",
                        content: ["Expectations in the English program include many opportunities for students to apply their language skills to work-related situations, to explore educational and career options, and to become self-directed learners. To prepare students for the literacy demands of a wide array of postsecondary educational programs and careers, English courses require students to develop research skills, practice expository writing, and learn strategies for understanding informational reading materials. Making oral presentations and working in small groups with classmates help students express themselves confidently and work cooperatively with others. Regardless of their post-secondary destination, all students need to realize that literacy skills are employability skills. Powerful literacy skills will equip students to manage information technologies, communicate effectively and correctly in a variety of situations, and perform a variety of tasks required in most work environments."],
                    },
                    {
                        title: "Cooperative Education and Other Workplace Experiences",
                        content: ["By applying the skills they have developed, students will readily connect their classroom learning to real-life activities in the world in which they live.Cooperative education and other workplace experiences will broaden their knowledge of employment opportunities in a wide range of fields. KAI School will try to help students link to Ministry programs to ensure that students have information concerning programs and opportunities."],
                    },
                    {
                        title: "Health and Safety",
                        content: ["The program provides the reading skills for the student to be able to explore the variety of concepts relating to health and safety in the workplace. In order to provide a suitable learning environment for the KAI School staff and students, it is critical that classroom practice and the learning environment complies with relevant federal, provincial, and municipal health and safety legislation and by-laws, including, but not limited to, the Workplace Safety and Insurance Act, the Workplace Hazardous Materials Information System (WHMIS), the Food and Drug Act, the Health Protection and Promotion Act, the Ontario Building Code, and the Occupational Health and Safety Act (OHSA). The OHSA requires all schools to provide a safe and productive learning and work environment for both students and employees."],
                    },
                ],
            },
        },

    },
    {
        title: "ESLDO",
        description: {
            department: "English as a Second Language, Level 4",
            code: "ESLDO",
            grade: "All",
            type: "Open",
            credit: "1.0",
            prerequisite: "ESLCO, English as a Second Language, Level 4, Open or equivalent",
            document: "English as a Second Language and English Literacy Development, The Ontario Curriculum, Grades 9 to 12, 2007",
            link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/esl912currb.pdf",
            developer: "KAI School",
            date: 2021,
            content: "This course prepares students to use English with increasing fluency and accuracy in classroom and social situations and to participate in Canadian society as informed citizens. Students will develop the oral presentation, reading, and writing skills required for success in all school subjects. They will extend listening and speaking skills through participation in discussions and seminars; study and interpret a variety of grade-level texts; write narratives, articles, and summaries in English; and respond critically to a variety of print and media texts.",
        },
        outline: {
            title: "English as a Second Language ESLCO-Total Hours(110 Hours)",
            units: [
                {
                    title: "Unit One",
                    hour: "20 hours",
                    content: "In this unit, students will describe the assessment criteria outlined in the provincial achievement charts; the prerequisites for post-secondary education and training, describe the types of courses offered for Grades 9-12, identify terms such as Ontario Student Record, Ontario Student Transcript, Transfer Course, and Specialist High Skills Major (SHSM), use correctly the grammatical structures and conventions of written English appropriate for this level, describe the types of courses offered for Grades 9-12, describes the types of courses offered for Grades 9-12, and describe the types of courses offered for.",
                },
                {
                    title: "Unit Two",
                    hour: "20 hours",
                    content: "In this unit, students will define media and cite its examples, differentiate media platforms from one another, identify the purposes of media, explain the importance of media in your day-to-day life, identify the importance of each era in media development, differentiate between advertisements in media VS real life, understand each element of the communication process, cite examples for each communication process step, identify each step in the communication process of advertising, analyze an advertisement using the communication process, define Media Literacy, understand different media texts and their points of view, create your advertisement for specific purposes and audiences, define and apply each step from the ABCDE Five-Step Strategy to your own experiences; and review and read media texts using the ABCDE Five-Step Strategy, and use correctly the grammatical structures and conventions of written English appropriate for this level.",
                },
                {
                    title: "Unit Three",
                    hour: "20 hours",
                    content: "In this unit, students will read “Piecing Me Together,” and demonstrate a grasp of the novel through lessons and reflections on the discussion forum in this unit. Students will also learn how to write a journal entry, see a video of Kimberlé Crenshaw's TED Talk on the urgency of intersectionality, and draw conclusions about the novel's character.",
                },
                {
                    title: "Unit Four",
                    hour: "20 hours",
                    content: "In this unit, students will demonstrate their knowledge of the writing process by responding to prompts that ask them to express their ideas and feelings about Canadian citizenship and diversity and its diversity by interpreting Watchmojo's video about Canadian stereotypes, demonstrate their understanding of Canadian citizenship and diversity by interpreting Watchmojo's video about Canadian stereotypes, and demonstrate their understanding of Canadian citizenship and diversity by interpreting Watchmojo's video about Canadian stereotypes, use a variety of strategies to read and comprehend news articles about the Canadian Charter of Rights and Freedoms, demonstrate an understanding of Canadian citizens' rights and responsibilities by reviewing and responding to sample scenarios, demonstrate an understanding of the three levels of government in Canada, and demonstrate an understanding of the Canadian economy, and use correlative reasoning.",
                },
                {
                    title: "Unit Five",
                    hour: "20 hours",
                    content: "In this unit, students will use a variety of connecting devices and transition words and phrases to show relationships between ideas and information in linked sentences and paragraphs, write longer and more complex texts to convey information and ideas for academic purposes in a variety of forms, and use the appropriate grammatical structures and conventions of written English.",
                },
                {
                    title: "Rich Task Summative 20%",
                    hour: "8 hours",
                    content: "This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through not only the product but through conversation and observation.",
                },
                {
                    title: "Final Exam 10%",
                    hour: "2 hours",
                    content: "This is a proctored exam worth 30% of your final grade.",
                },
            ],
            note1: "This course is entirely online and does not require or rely on any textbook.",
            note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

        },
        expectations: {
            title: "ESLCO English as a Second LanguageESL Level 3 Open",
            content: [
                {
                    title: "Listening and Speaking",
                    overall: [
                        "demonstrate the ability to understand, interpret, and evaluate spoken English for a variety of purposes",
                        "use speaking skills and strategies to communicate in English for a variety of classroom and social purposes",
                        "use correctly the language structures appropriate for this level to communicate orally in English.",
                    ]
                },
                {
                    title: "Reading",
                    overall: [
                        "use a variety of reading strategies throughout the reading process to extract meaning from texts;",
                        "use a variety of strategies to build vocabulary;",
                        "locate and extract relevant information from written and graphic texts for a variety of purposes.",
                    ]
                },
                {
                    title: "Writing",
                    overall: [
                        "write in a variety of forms for different purposes and audiences;",
                        "organize ideas coherently in writing;",
                        "use correctly the conventions of written English appropriate for this level, including grammar,usage, spelling, and punctuation;",
                    ]
                },
                {
                    title: "Socio-Cultural Competence And Media Literacy",
                    overall: [
                        "demonstrate the ability to understand, interpret, and evaluate spoken English for a variety of purposes;",
                        "use speaking skills and strategies to communicate in English for a variety of classroom and social purposes;",
                        "use correctly the language structures appropriate for this level to communicate orally in English.",
                    ]
                }
            ]
        },
        assessment: {
            content: "",
            tab: [
                {
                    title: "Assessment For Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment for Learning takes place in preparation for course or unit learning ",
                },
                {
                    title: "Assessment As Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment as Learning takes place during or while learning",
                },
                {
                    title: "Assessment Of Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment Of Learning takes place after learning.",
                }
            ],
        },
        strategy: {
            p1: "Students are exposed to a variety of genres throughout the course and develop skills to analyze and evaluate the effectiveness of texts which may include poems, short stories, novels, non-fiction texts, plays, videos, and songs or other media texts from a wide range of cultures and time periods. Students identify and use various strategies including building vocabulary, learning to understand and use features and organization of texts, and developing knowledge of conventions. Throughout the course, students develop into stronger readers, writers, and oral communicators while making connections to the workplace and international events.",
            p2: "Teachers differentiate instruction to meet the diverse learning needs of students. Instructors also use electronic stimuli including Discussion Boards, Google Docs, Skype, Google Hangout and Screencastify to assist students in reflecting on their learning, and in setting goals for improvement in key areas while developing 21st century skills. These tools facilitate and support the editing and revising process for students as they create texts for different audiences and purposes.",
            lst: [
                "Identifying and developing skills and strategies – through modeling of effective skills, students learn to choose and utilize varied techniques to become effective readers, writers, and oral communicators.",
                "Communicating – several opportunities are provided for students to write and communicate orally.",
                "Generating ideas and topics – teachers encourage students to design their own approaches to the material by maintaining frequent (often daily) online communication with students, by allowing some freedom in how students respond to topics and questions, and by encouraging students’ independent thinking through discussion posts.",
                "Researching – various approaches to researching are practised. Students learn how to cite sources and provide a works cited page at the end of longer assignments using MLA formatting.",
                "Thinking critically – students learn to critically analyze texts and to use implied and stated evidence from texts to support their analyses. Students use their critical thinking skills to identify perspectives in texts, including biases that may be present.",
                "Producing published work and making presentations – students engage in the editing and revising process, including self-revision, peer revision, and teacher revision all of which strengthen texts with the aim to publish or present student work.",
                "Reflecting – through the ePortfolio and other elements of the course, students reflect on the learning process, focus on areas for improvement, and make extensions between course content and their personal experiences.",
            ]
        },
        evaluation: {
            title: "Assessment and Evaluation and Reporting Strategies of Student Performance",
            p1: "Our theory of assessment and evaluation follows the Ministry of Education’s Growing Success document, and it is our firm belief that doing so is in the best interests of students. We seek to design assessment in such a way as to make it possible to gather and show evidence of learning in a variety of ways to gradually release responsibility to the students and to give multiple and varied opportunities to reflect on learning and receive detailed feedback.",
            p2: "Growing Success articulates the vision the Ministry has for the purpose and structure of assessment and evaluation techniques. There are seven fundamental principles that ensure best practices and procedures of assessment and evaluation by KAI School teachers. KAI School assessments and evaluations,",
            lst: [
                "are fair, transparent, and equitable for all students;",
                "support all students, including those with special education needs, those who are learning the language of instruction (English or French), and those who are First Nation, Métis, or Inuit;",
                "are carefully planned to relate to the curriculum expectations and learning goals and, as much as possible, to the interests, learning styles and preferences, needs, and experiences of all students;",
                "are communicated clearly to students and parents at the beginning of the course and at other points throughout the school year or course;",
                "are ongoing, varied in nature, and administered over a period of time to provide multiple opportunities for students to demonstrate the full range of their learning;",
                "provide ongoing descriptive feedback that is clear, specific, meaningful, and timely to support improved learning and achievement;",
                "develop students’ self-assessment skills to enable them to assess their own learning, set specific goals, and plan next steps for their learning.",
            ],
            p3: "For a full explanation, please refer to Growing Success.",
            link: "http://www.edu.gov.on.ca/eng/policyfunding/growsuccess.pdf",
        },
        grade: {
            p1: "The evaluation for this course is based on the student’s achievement of curriculum expectations and the demonstrated skills required for effective learning. The final percentage grade represents the quality of the student’s overall achievement of the expectations for the course and reflects the corresponding level of achievement as described in the achievement chart for the discipline. A credit is granted and recorded for this course if the student’s grade is 50% or higher. The final grade will be determined as follows:",
            lst: [
                "70% of the grade will be based upon evaluations conducted throughout the course. This portion of the grade will reflect the student’s most consistent level of achievement throughout the course, although special consideration will be given to more recent evidence of achievement.",
                "30% of the grade will be based on final evaluations administered at the end of the course. The final assessment may be a final exam, a final project, or a combination of both an exam and a project.",
            ],
        },
        report: {
            p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
            p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
            p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
        },
        consideration: {
            p1: "Teachers who are planning a program in English must take into account considerations in a number of important areas. Essential information that pertains to all disciplines is provided in the companion piece to this document, The Ontario Curriculum, Grades 9 to 12: Program Planning and Assessment, 2000. The areas of concern to all teachers that are outlined here include the following:",
            lst: [
                "types of secondary school courses",
                "education for exceptional students",
                "the role of technology in the curriculum",
                "English as a second language (ESL) and English literacy development (ELD)",
                "career education",
                "cooperative education and other workplace experiences",
                "health and safety",
            ],
            technology: {
                p1: "Information and communications technologies (ICT) provide a range of tools that can significantly extend and enrich teachers’ instructional strategies and support students’ language learning. ICT tools include multimedia resources, databases, Internet websites, digital cameras, and word-processing programs. Tools such as these can help students to collect, organize, and sort the data they gather and to write, edit, and present reports on their findings.",
            },
            exceptional: {
                p1: "In planning courses, teachers should take into account the needs of exceptional students as set out in their Individual Education Plan. English courses reflect the creative part of our literary world, which offers a vast array of opportunities for exceptional students. Students who use alternative techniques for communication may find a venue for their talents as writers. Just as English responds to the needs and demands of the greater world of work, English courses are largely shaped by the needs and demands of students who will all eventually end up in this greater world.",
            },
            development: {
                title: "English As a Second Language and English Literacy Development",
                title2: "(ESL/ELD)",
                p1: "With exposure to the English language in a supportive learning environment, most young children will develop oral fluency quite quickly, making connections between concepts and skills acquired in their first language and similar concepts and skills presented in English. However, oral fluency is not a good indicator of a student’s knowledge of vocabulary or sentence structure, reading comprehension, or other aspects of language proficiency that play an important role in literacy development and academic success. Research has shown that it takes five to seven years for most English language learners to catch up to their English-speaking peers in their ability to use English for academic purposes. Moreover, the older the children are when they arrive, the greater the language knowledge and skills that they have to catch up on, and the more direct support they require from their teachers. Responsibility for students’ English-language development is shared by the course teacher, the ESL/ELD teacher (where available), and other school staff. Volunteers and peers may also be helpful in supporting English language learners in the language classroom. Teachers must adapt the instructional program in order to facilitate the success of these students in their classrooms. Appropriate adaptations include:",
                lst: [
                    "modification of some or all of the subject expectations so that they are challenging but attainable for the learner at his or her present level of English proficiency, given the necessary support from the teacher;",
                    "use of a variety of instructional strategies (e.g., extensive use of visual cues, graphic organizers, scaffolding; previewing of textbooks, pre-teaching of key vocabulary; peer tutoring; strategic use of students’ first languages);",
                    "use of a variety of learning resources (e.g., visual material, simplified text, bilingual dictionaries, and materials that reflect cultural diversity);",
                    "use of assessment accommodations (e.g., granting of extra time; use of oral interviews, demonstrations or visual representations, or tasks requiring completion of graphic organizers or close sentences instead of essay questions and other assessment tasks that depend heavily on proficiency in English).",
                ],
                note: "When learning expectations in any course are modified for an English language learner (whether the student is enrolled in an ESL or ELD course or not), this information must be clearly indicated on the student’s report card.",
                p2: "This course can provide a wide range of options to address the needs of ESL/ELD students. Detailed analysis of the components of sentences aid ESL students in mastering the English language and all of its idiosyncrasies. In addition, since all occupations require employees with a wide range of English skills and abilities, many students will learn how their backgrounds and language skills can contribute to their success in the larger world.",
                p3: "Antidiscrimination Education in the English Program Learning resources that reflect the broad range of students’ interests, backgrounds, cultures, and experiences are an important aspect of an inclusive English program. In such a program, learning materials involve protagonists of both sexes from a wide variety of backgrounds.",
                p4: "Literacy, Mathematical Literacy, and Inquiry/Research Skills Literacy, mathematical literacy, and inquiry/research skills are critical to students’ success in all subjects of the curriculum and in all areas of their lives. The acquisition and development of literacy skills is clearly the focus of the English curriculum,",
                tab: [
                    {
                        title: "Career Education",
                        content: ["Expectations in the English program include many opportunities for students to apply their language skills to work-related situations, to explore educational and career options, and to become self-directed learners. To prepare students for the literacy demands of a wide array of postsecondary educational programs and careers, English courses require students to develop research skills, practice expository writing, and learn strategies for understanding informational reading materials. Making oral presentations and working in small groups with classmates help students express themselves confidently and work cooperatively with others. Regardless of their post-secondary destination, all students need to realize that literacy skills are employability skills. Powerful literacy skills will equip students to manage information technologies, communicate effectively and correctly in a variety of situations, and perform a variety of tasks required in most work environments."],
                    },
                    {
                        title: "Cooperative Education and Other Workplace Experiences",
                        content: ["By applying the skills they have developed, students will readily connect their classroom learning to real-life activities in the world in which they live.Cooperative education and other workplace experiences will broaden their knowledge of employment opportunities in a wide range of fields. KAI School will try to help students link to Ministry programs to ensure that students have information concerning programs and opportunities."],
                    },
                    {
                        title: "Health and Safety",
                        content: ["The program provides the reading skills for the student to be able to explore the variety of concepts relating to health and safety in the workplace. In order to provide a suitable learning environment for the KAI School staff and students, it is critical that classroom practice and the learning environment complies with relevant federal, provincial, and municipal health and safety legislation and by-laws, including, but not limited to, the Workplace Safety and Insurance Act, the Workplace Hazardous Materials Information System (WHMIS), the Food and Drug Act, the Health Protection and Promotion Act, the Ontario Building Code, and the Occupational Health and Safety Act (OHSA). The OHSA requires all schools to provide a safe and productive learning and work environment for both students and employees."],
                    },
                ],
            },
        },

    },
    {
        title: "ESLEO",
        description: {
            department: "English as a Second Language, Level 5",
            code: "ESLEO",
            grade: "All",
            type: "Open",
            credit: "1.0",
            prerequisite: "ESLDO, English as a Second Language, Level 5, Open or equivalent",
            document: "English as a Second Language and English Literacy Development, The Ontario Curriculum, Grades 9 to 12, 2007",
            link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/esl912currb.pdf",
            developer: "KAI School",
            date: 2021,
            content: "This course provides students with the skills and strategies they need to make the transition to college and university preparation courses in English and other secondary school disciplines. Students will be encouraged to develop independence in a range of academic tasks. They will participate in debates and lead classroom workshops; read and interpret literary works and academic texts; write essays, narratives, and reports; and apply a range of learning strategies and research skills effectively. Students will further develop their ability to respond critically to print and media texts. ",
        },
        outline: {
            title: "English as a Second Language ESLCO-Total Hours(110 Hours)",
            units: [
                {
                    title: "Unit One",
                    hour: "20 hours",
                    content: "In this unit, students will visit an interactive atlas and timeline of Canada to extract relevant information about its geography and history, read and demonstrate their understanding of the various national symbols of Canada, read and demonstrate their understanding of the Canadian Charter of Rights and Freedoms, read and demonstrate their understanding of the relationship between Canada and the United States, read and demonstrate their understanding of the Canadian Charter of Rights and Freedoms, read and demonstrate their understanding of the Canadian Charter of Rights and Freedoms, read and demonstrate their understanding of various articles and media texts to understand the relationship between Canada and the United States. ",
                },
                {
                    title: "Unit Two",
                    hour: "20 hours",
                    contetn: "Unit Two: In this unit, students will demonstrate an understanding of Belling the Cat and The Wedding Present through PACTS, write short texts to express ideas and feelings on personal topics using RACES, demonstrate an understanding of different literary devices, analyse All in a Summer Day to identify literary elements and explain their effect on the reader, analyse “The Masque of Red Death” by Edgar Allan Poe to identify literary devices and explain their effect on the reader, analyse texts in a range of genres, including essays, short stories, novels, poems, and drama to identify literary devices and explain their effect on the reader, and use correctly the grammatical structures and conventions of written English appropriate for this level. ",
                },
                {
                    title: "Unit Three",
                    hour: "20 hours",
                    content: "In this unit, students will define Media Literacy, view, read, and listen to various media texts and explain how they influence society, understand Bias, and understand the concept of privilege and how it applies to media, demonstrate an understanding of different points of view, and suggest reasons why particular perspectives are presented, define Media Literacy, view, read, and listen to various media texts and explain how they influence society, understand Bias, and use correctly the grammatical rules.",
                },
                {
                    title: "Unit Four",
                    hour: "20 hours",
                    content: "In this unit, students will read the novel 'The Hunger Games, ' and develop a character chart, learn how to interview, read an article by Dr. Karen Carr about Roman Gladiators, and fill out a chart describing how each identified gift anticipated a later event in this unit. ",
                },
                {
                    title: "Unit Five",
                    hour: "20 hours",
                    content: "In this unit, students will determine and use the appropriate language when introducing themselves, identify when it is appropriate to use humour in social situations, determine and use the appropriate language when talking about their life at home, determine and use the appropriate language when talking about your day at school, understand vocabulary around prejudice and discrimination, understand your role in caring for the environment as a member of the community, use signal words appropriately to build an opinion essay about environmental issues, and use correctly the grammatical structures and conventions of written English appropriate for this level.",
                },
                {
                    title: "Rich Task Summative 20%",
                    hour: "8 hours",
                    content: "This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through not only the product but through conversation and observation.",
                },
                {
                    title: "Final Exam 10%",
                    hour: "2 hours",
                    content: "This is a proctored exam worth 30% of your final grade.",
                },
            ],
            note1: "This course is entirely online and does not require or rely on any textbook.",
            note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

        },
        expectations: {
            title: "ESLCO English as a Second LanguageESL Level 3 Open",
            content: [
                {
                    title: "Listening and Speaking",
                    overall: [
                        "demonstrate the ability to understand, interpret, and evaluate spoken English for a variety of purposes",
                        "use speaking skills and strategies to communicate in English for a variety of classroom and social purposes",
                        "use correctly the language structures appropriate for this level to communicate orally in English.",
                    ]
                },
                {
                    title: "Reading",
                    overall: [
                        "use a variety of reading strategies throughout the reading process to extract meaning from texts;",
                        "use a variety of strategies to build vocabulary;",
                        "locate and extract relevant information from written and graphic texts for a variety of purposes.",
                    ]
                },
                {
                    title: "Writing",
                    overall: [
                        "write in a variety of forms for different purposes and audiences;",
                        "organize ideas coherently in writing;",
                        "use correctly the conventions of written English appropriate for this level, including grammar,usage, spelling, and punctuation;",
                    ]
                },
                {
                    title: "Socio-Cultural Competence And Media Literacy",
                    overall: [
                        "demonstrate the ability to understand, interpret, and evaluate spoken English for a variety of purposes;",
                        "use speaking skills and strategies to communicate in English for a variety of classroom and social purposes;",
                        "use correctly the language structures appropriate for this level to communicate orally in English.",
                    ]
                }
            ]
        },
        assessment: {
            content: "",
            tab: [
                {
                    title: "Assessment For Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment for Learning takes place in preparation for course or unit learning ",
                },
                {
                    title: "Assessment As Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment as Learning takes place during or while learning",
                },
                {
                    title: "Assessment Of Learning",
                    p: "The teacher will obtain assessment information through a variety of means as indicated in the chart below. Assessment and Evaluation Strategies are to include the evidence or proof the teacher sees in the Product, Observations and Conversations related to the curriculum expectations. The student must demonstrate achievement of the course expectations. Once demonstrated, the student is assigned a level of achievement.",
                    p2: "Assessment Of Learning takes place after learning.",
                }
            ],
        },
        strategy: {
            p1: "Students are exposed to a variety of genres throughout the course and develop skills to analyze and evaluate the effectiveness of texts which may include poems, short stories, novels, non-fiction texts, plays, videos, and songs or other media texts from a wide range of cultures and time periods. Students identify and use various strategies including building vocabulary, learning to understand and use features and organization of texts, and developing knowledge of conventions. Throughout the course, students develop into stronger readers, writers, and oral communicators while making connections to the workplace and international events.",
            p2: "Teachers differentiate instruction to meet the diverse learning needs of students. Instructors also use electronic stimuli including Discussion Boards, Google Docs, Skype, Google Hangout and Screencastify to assist students in reflecting on their learning, and in setting goals for improvement in key areas while developing 21st century skills. These tools facilitate and support the editing and revising process for students as they create texts for different audiences and purposes.",
            lst: [
                "Identifying and developing skills and strategies – through modeling of effective skills, students learn to choose and utilize varied techniques to become effective readers, writers, and oral communicators.",
                "Communicating – several opportunities are provided for students to write and communicate orally.",
                "Generating ideas and topics – teachers encourage students to design their own approaches to the material by maintaining frequent (often daily) online communication with students, by allowing some freedom in how students respond to topics and questions, and by encouraging students’ independent thinking through discussion posts.",
                "Researching – various approaches to researching are practised. Students learn how to cite sources and provide a works cited page at the end of longer assignments using MLA formatting.",
                "Thinking critically – students learn to critically analyze texts and to use implied and stated evidence from texts to support their analyses. Students use their critical thinking skills to identify perspectives in texts, including biases that may be present.",
                "Producing published work and making presentations – students engage in the editing and revising process, including self-revision, peer revision, and teacher revision all of which strengthen texts with the aim to publish or present student work.",
                "Reflecting – through the ePortfolio and other elements of the course, students reflect on the learning process, focus on areas for improvement, and make extensions between course content and their personal experiences.",
            ]
        },
        evaluation: {
            title: "Assessment and Evaluation and Reporting Strategies of Student Performance",
            p1: "Our theory of assessment and evaluation follows the Ministry of Education’s Growing Success document, and it is our firm belief that doing so is in the best interests of students. We seek to design assessment in such a way as to make it possible to gather and show evidence of learning in a variety of ways to gradually release responsibility to the students and to give multiple and varied opportunities to reflect on learning and receive detailed feedback.",
            p2: "Growing Success articulates the vision the Ministry has for the purpose and structure of assessment and evaluation techniques. There are seven fundamental principles that ensure best practices and procedures of assessment and evaluation by KAI  School teachers. KAI School assessments and evaluations,",
            lst: [
                "are fair, transparent, and equitable for all students;",
                "support all students, including those with special education needs, those who are learning the language of instruction (English or French), and those who are First Nation, Métis, or Inuit;",
                "are carefully planned to relate to the curriculum expectations and learning goals and, as much as possible, to the interests, learning styles and preferences, needs, and experiences of all students;",
                "are communicated clearly to students and parents at the beginning of the course and at other points throughout the school year or course;",
                "are ongoing, varied in nature, and administered over a period of time to provide multiple opportunities for students to demonstrate the full range of their learning;",
                "provide ongoing descriptive feedback that is clear, specific, meaningful, and timely to support improved learning and achievement;",
                "develop students’ self-assessment skills to enable them to assess their own learning, set specific goals, and plan next steps for their learning.",
            ],
            p3: "For a full explanation, please refer to Growing Success.",
            link: "http://www.edu.gov.on.ca/eng/policyfunding/growsuccess.pdf",
        },
        grade: {
            p1: "The evaluation for this course is based on the student’s achievement of curriculum expectations and the demonstrated skills required for effective learning. The final percentage grade represents the quality of the student’s overall achievement of the expectations for the course and reflects the corresponding level of achievement as described in the achievement chart for the discipline. A credit is granted and recorded for this course if the student’s grade is 50% or higher. The final grade will be determined as follows:",
            lst: [
                "70% of the grade will be based upon evaluations conducted throughout the course. This portion of the grade will reflect the student’s most consistent level of achievement throughout the course, although special consideration will be given to more recent evidence of achievement.",
                "30% of the grade will be based on final evaluations administered at the end of the course. The final assessment may be a final exam, a final project, or a combination of both an exam and a project.",
            ],
        },
        report: {
            p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
            p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
            p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
        },
        consideration: {
            p1: "Teachers who are planning a program in English must take into account considerations in a number of important areas. Essential information that pertains to all disciplines is provided in the companion piece to this document, The Ontario Curriculum, Grades 9 to 12: Program Planning and Assessment, 2000. The areas of concern to all teachers that are outlined here include the following:",
            lst: [
                "types of secondary school courses",
                "education for exceptional students",
                "the role of technology in the curriculum",
                "English as a second language (ESL) and English literacy development (ELD)",
                "career education",
                "cooperative education and other workplace experiences",
                "health and safety",
            ],
            technology: {
                p1: "Information and communications technologies (ICT) provide a range of tools that can significantly extend and enrich teachers’ instructional strategies and support students’ language learning. ICT tools include multimedia resources, databases, Internet websites, digital cameras, and word-processing programs. Tools such as these can help students to collect, organize, and sort the data they gather and to write, edit, and present reports on their findings.",
            },
            exceptional: {
                p1: "In planning courses, teachers should take into account the needs of exceptional students as set out in their Individual Education Plan. English courses reflect the creative part of our literary world, which offers a vast array of opportunities for exceptional students. Students who use alternative techniques for communication may find a venue for their talents as writers. Just as English responds to the needs and demands of the greater world of work, English courses are largely shaped by the needs and demands of students who will all eventually end up in this greater world.",
            },
            development: {
                title: "English As a Second Language and English Literacy Development",
                title2: "(ESL/ELD)",
                p1: "With exposure to the English language in a supportive learning environment, most young children will develop oral fluency quite quickly, making connections between concepts and skills acquired in their first language and similar concepts and skills presented in English. However, oral fluency is not a good indicator of a student’s knowledge of vocabulary or sentence structure, reading comprehension, or other aspects of language proficiency that play an important role in literacy development and academic success. Research has shown that it takes five to seven years for most English language learners to catch up to their English-speaking peers in their ability to use English for academic purposes. Moreover, the older the children are when they arrive, the greater the language knowledge and skills that they have to catch up on, and the more direct support they require from their teachers. Responsibility for students’ English-language development is shared by the course teacher, the ESL/ELD teacher (where available), and other school staff. Volunteers and peers may also be helpful in supporting English language learners in the language classroom. Teachers must adapt the instructional program in order to facilitate the success of these students in their classrooms. Appropriate adaptations include:",
                lst: [
                    "modification of some or all of the subject expectations so that they are challenging but attainable for the learner at his or her present level of English proficiency, given the necessary support from the teacher;",
                    "use of a variety of instructional strategies (e.g., extensive use of visual cues, graphic organizers, scaffolding; previewing of textbooks, pre-teaching of key vocabulary; peer tutoring; strategic use of students’ first languages);",
                    "use of a variety of learning resources (e.g., visual material, simplified text, bilingual dictionaries, and materials that reflect cultural diversity);",
                    "use of assessment accommodations (e.g., granting of extra time; use of oral interviews, demonstrations or visual representations, or tasks requiring completion of graphic organizers or close sentences instead of essay questions and other assessment tasks that depend heavily on proficiency in English).",
                ],
                note: "When learning expectations in any course are modified for an English language learner (whether the student is enrolled in an ESL or ELD course or not), this information must be clearly indicated on the student’s report card.",
                p2: "This course can provide a wide range of options to address the needs of ESL/ELD students. Detailed analysis of the components of sentences aid ESL students in mastering the English language and all of its idiosyncrasies. In addition, since all occupations require employees with a wide range of English skills and abilities, many students will learn how their backgrounds and language skills can contribute to their success in the larger world.",
                p3: "Antidiscrimination Education in the English Program Learning resources that reflect the broad range of students’ interests, backgrounds, cultures, and experiences are an important aspect of an inclusive English program. In such a program, learning materials involve protagonists of both sexes from a wide variety of backgrounds.",
                p4: "Literacy, Mathematical Literacy, and Inquiry/Research Skills Literacy, mathematical literacy, and inquiry/research skills are critical to students’ success in all subjects of the curriculum and in all areas of their lives. The acquisition and development of literacy skills is clearly the focus of the English curriculum,",
                tab: [
                    {
                        title: "Career Education",
                        content: ["Expectations in the English program include many opportunities for students to apply their language skills to work-related situations, to explore educational and career options, and to become self-directed learners. To prepare students for the literacy demands of a wide array of postsecondary educational programs and careers, English courses require students to develop research skills, practice expository writing, and learn strategies for understanding informational reading materials. Making oral presentations and working in small groups with classmates help students express themselves confidently and work cooperatively with others. Regardless of their post-secondary destination, all students need to realize that literacy skills are employability skills. Powerful literacy skills will equip students to manage information technologies, communicate effectively and correctly in a variety of situations, and perform a variety of tasks required in most work environments."],
                    },
                    {
                        title: "Cooperative Education and Other Workplace Experiences",
                        content: ["By applying the skills they have developed, students will readily connect their classroom learning to real-life activities in the world in which they live.Cooperative education and other workplace experiences will broaden their knowledge of employment opportunities in a wide range of fields. KAI School will try to help students link to Ministry programs to ensure that students have information concerning programs and opportunities."],
                    },
                    {
                        title: "Health and Safety",
                        content: ["The program provides the reading skills for the student to be able to explore the variety of concepts relating to health and safety in the workplace. In order to provide a suitable learning environment for the KAI School staff and students, it is critical that classroom practice and the learning environment complies with relevant federal, provincial, and municipal health and safety legislation and by-laws, including, but not limited to, the Workplace Safety and Insurance Act, the Workplace Hazardous Materials Information System (WHMIS), the Food and Drug Act, the Health Protection and Promotion Act, the Ontario Building Code, and the Occupational Health and Safety Act (OHSA). The OHSA requires all schools to provide a safe and productive learning and work environment for both students and employees."],
                    },
                ],
            },
        },

    },

]