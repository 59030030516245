export const AVI1O = {
    title: "Visual Arts",
    description: {
        code: "AVI1O",
        department: "Arts",
        type: "Open",
        grade: "9",
        credit: "1.0",
        prerequisite: "None",
        document: "The Arts, The Ontario Curriculum, Grades 9 and 10, 2010",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/arts910curr2010.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent learning tutorials, essays, critical analysis, digital media activities, video and photography creation. Presentation techniques form the basis of study as students create oral presentations through screencasts, and audio files in conjunction with media arts-related assignments that reflect their understanding of visual arts.",
    },
    outline: {
        title: "AVI1O, Visual Arts, Grade 9 ",
        units: [
            {
                title: "Unit One:  Drawing",
                hour: "25 Hours",
                content: "In this unit students will further develop their understanding of the elements and principles of design, the creative process. Students will learn to draw simple still lifes and apply basic drawing techniques.They will be able to discuss how colour can be used to convey particular moods and messages in artworks and distinguish between one, two and three point perspectives.Students will use an online drawing tool to create digital drawings and explore some contemporary artists that use drawing as their medium.",
            },
            {
                title: "Unit Two: Photography",
                hour: "25 Hours",
                contetn: "In this unit, students will apply and practice creating images by recording light or other electromagnetic radiation, either electronically with an image sensor or chemically with a light-sensitive material such as photographic film. They will also learn about some commonly used photo editing techniques. Students will be able to reflect on the different meanings and messages that can be relayed by photos, they will explore which careers incorporate knowledge of photography and learn about artists that use photography.",
            },
            {
                title: "Unit Three: Digital Media",
                hour: "25 Hours",
                content: "In this unit, students will discuss how digital media is present in and influences their daily lives. They will create various art works using Tinkercad, Animaker and SculptGL.",
            },
            {
                title: "Unit Four: Art History",
                hour: "25 Hours",
                content: "In this unit, students will examine various forms of art from different time periods and countries such as the prehistoric art,  Egyptian art, Greek and Roman art and Canadian Artists  They will come to understand the social impact art has and why it is specifically created.",
            },
            {
                title: "RST",
                hour: "10 Hours",
                content: "RST worth 30% of final mark: Students will create a summative that will covers all of the expectations of the course. ",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "AVI1O,Visual Art, Grade 9,",
        content: [
            {
                title: "Creating And Presenting",
                overall: [
                    "The Creative Process: apply the creative process to create a variety of art works, individually and/or collaboratively;",
                    "The Elements and Principles of Design: apply elements and principles of design to create art works for the purpose of self- expression and to communicate ideas, information, and/or messages;",
                    "Production and Presentation:produce art works, using a variety of media/materials and traditional and/or emerging technologies, tools, and techniques, and demonstrate an understanding of a variety of ways of presenting their works and the works of others.",
                ]
            },
            {
                title: "Reflecting, Responding and Analysing",
                overall: [
                    "The Critical Analysis Process:demonstrate an understanding of the critical analysis process by examining, interpreting,evaluating, and reflecting on various artworks;",
                    "Art, Society, and Values: demonstrate an understanding of how art works reflect the society in which they were created, and of how they can affect personal values;",
                    "Connections Beyond the Classroom: demonstrate an understanding of the types of knowledge and skills developed in visual arts, and identify various opportunities related to visual arts.",
                ]
            },
            {
                title: "Foundations",
                overall: [
                    "Terminology: demonstrate an understanding of, and use correct terminology when referring to, elements, principles, and other components related to visual arts;",
                    "Conventions and Techniques: demonstrate an understanding of conventions and techniques used in the creation of visual art works;",
                    "Responsible Practices: demonstrate an understanding of responsible practices related to visual arts.",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                content: [
                    "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                    "<ul><li>Ongoing</li><li>Is tied to learning outcomes</li><li>Provides information that structures the teachers’ planning and instruction</li><li>Allows teachers to provide immediate and descriptive feedback that will guide student learning</li>",
                    "The purpose of assessment for learning is to create self-regulated and lifelong learners."
                ]
            },
            {
                title: "Assessment as Learning: ",
                content: ["Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                    "",
                    "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",]
            },
            {
                title: "Assessment of Learning: ",
                content: ["Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                    "",
                    "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
                ]
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Visual Arts provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent learning tutorials, essays, critical analysis, digital media activities, video and photography creation. Presentation techniques form the basis of study as students create oral presentations through screencasts, and audio files in conjunction with media arts-related assignments that reflect their understanding of visual arts. ",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Creating and presenting, Reflecting, Responding and Analysing and foundations strategies enhance students to develop and refine their critical and creative skills, problem-solving skills, and communication skills, while engaged in arts activities, projects, and exploration.",
            "Videos in the course illustrate topics such as drawing techniques, photography, digital media and art history lectures.",
            "Scaffolding longer visual arts-related assignments allow students to work with the process of creating. Teacher feedback at each level enables students to improve both style and content in their art work projects.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their media arts projects.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Visual_Art: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },

}