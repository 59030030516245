export const LKBDU = {
    title: "International Languages- Mandarin",
    description: {
        code: "LKBDU",
        department: "Simplified Chinese",
        type: "Academic",
        credit: "1.0",
        grade: "10",
        prerequisite: "None",
        document: "Mandarin as a Second Language, The Ontario Curriculum, Grades 9 to 12, 2014",
        link: "",
        developer: "KAI School",
        date: 2021,
        content: "This course prepares students for university studies in Mandarin.Students will enhance their ability to use the language with clarity and precision and will develop the language skills needed to engage in sustained conversations and discussions, understand and evaluate information, read diverse materials for both study and pleasure, and write clearly and effectively.Students will also have opportunities to add to their knowledge of Chinese culture through the use of community resources and computer technology.",
    },
    outline: {
        title: "LKBDU International Languages- Mandarin",
        units: [
            {
                title: "Unit One: Short Stories and essays",
                hour: "20 Hours",
                content: "In this unit, students will receive an overview of Chinese culture and history. The curriculum will cover significant mythological and historical figures/events in Chinese history, exploring their impact on classical literature and Chinese culture. The study will extend to Chinese fables and commonly used phrases/proverbs derived from them. Students will engage in discussions surrounding these elements, fostering a deeper understanding.Additionally, the unit will involve an exploration of Chinese prose, short stories, and reading and writing strategies. This understanding will be facilitated through the review of exemplary works by renowned Chinese writers.",
            },
            {
                title: "Unit Two: Chinese Cultures and application of Mandarin",
                hour: "20 Hours",
                contetn: "In this unit, students will delve into the major traditional holidays and Chinese proverbs guided by the Traditional Chinese Calendar and the 24 solar terms.The exploration extends to the etiquettes, forms, and phrases employed in written communications. Additionally, students will gain insight into the history and evolution of Chinese Calligraphy, encompassing various script styles.Furthermore, the unit will cover the 8 major Chinese cuisines, the tea culture, and its influence within Chinese communities. Students will also study the impact and history of liquor in Chinese culture.",
            },
            {
                title: "Unit Three: Classical Chinese Literature",
                hour: "20 Hours",
                content: "In this unit, students will review the evolution of classical Chinese literature, with a focus on poetry, prose, plays, and novels.The curriculum encourages the integration of students' overall knowledge and learning experiences related to famous classical Chinese poetry throughout history. Furthermore, students will acquire the skills to construct a structured draft for a short story, enhancing their proficiency in narrative composition",
            },
            {
                title: "Unit Four: Art and Multimedia",
                hour: "20 Hours",
                content: "In this unit, students will delve into various forms of traditional art and artistic elements within Chinese culture and communities. The curriculum includes an exploration of the four main academic and artistic talents expected of ancient Chinese scholars. Additionally, students will study the distinctive styles, design concepts, and cultural references inherent in traditional Chinese architecture.Further topics include the exploration of traditional Chinese music genres, dramas, and the Eight Sounds. Students will also gain insights into the origins, cultural references, and collective mindset associated with the Wuxia Spirits within Chinese culture. Lastly, the unit will facilitate connections between the artistic and cultural references of the Wuxia philosophy and the film 'Crouching Tiger, Hidden Dragon.'"
            },
            {
                title: "Unit Five: Novel Study: Dream of the Red Chamber",
                hour: "20 Hours",
                content: "In this unit, students will engage with 'Dream of the Red Chamber,' a renowned and classical novel in Chinese history. The curriculum includes an exploration of the novel's writing background and the stories behind its creation.Students will delve into the characters, understanding their roles in the narrative and the symbolic representations they carry within the novel. The unit will explore the plot, focusing on the most discussed topics and myths associated with the novel.Further, students will analyze the behaviors, motivations, and personality types of the major characters. They will gain an understanding of the conflicts these characters experience and their overall roles in the story. The unit concludes with students articulating important ideas about the novel, allowing for a comprehensive analysis from both micro and macro perspectives."
            },
            {
                title: "Exam/RST",
                hour: "10 Hours",
                content: "The RST is 30% of the Final Mark. This is a summative task that serves as the culminating assessment at the conclusion of the course. It consolidates various expectations covered throughout the curriculum, evaluating students on their ability to demonstrate understanding not only through the final product but also through active engagement in conversation and observation.",
            },
        ],
        note1: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work.",
        note2: "Laptop and/or personal computer (preferably with Google Chrome or Mozilla Firefox as a web browser)",
        note3: "Access to video recording and handwritten work scanning (mobile phone, tablet, iPad, webcams)",
        note4: "Stable internet connection",
        note5: "A non-programmable, non-graphing, scientific calculator.",

    },
    expectations: {
        title: "LKBDU: International Languages- Mandarin,Grade 10",
        content: [
            {
                title: "Listening",
                overall: [
                    "Listening to Understand: determine meaning in a variety of oral texts in the target language, using a range of listening strategies;",
                    "Listening to Interact: interpret messages accurately while interacting in the target language for a variety of purposes and with diverse audiences",
                    "ntercultural Understanding: demonstrate an understanding of information in oral texts in the target language about aspects of culture in diverse communities where the target language is spoken and other communities around the world, and of sociolinguistic conventions in the target language used in a variety of situations and communities",
                ],
            },
            {
                title: "Speaking",
                overall: [
                    "Speaking to Communicate: communicate information and ideas orally in the target language, using a range of speaking strategies, appropriate language structures, and level-appropriate language suited to the purpose and audience;",
                    "Speaking to Interact: participate in spoken interactions in the target language for a variety of purposes and with diverse audiences;",
                    "Intercultural Understanding: in their spoken communications in the target language, demonstrate an awareness of aspects of culture in diverse communities where the target language is spoken and other communities around the world, and of the appropriate use of sociolinguistic conventions in the target language in a variety of situations. ",
                ]
            },
            {
                title: "Reading",
                overall: [
                    "Reading Comprehension: determine meaning in a variety of texts in the target language, using a range of reading comprehension strategies; ",
                    "Purpose, Form, and Style: identify the purpose(s), characteristics, and aspects of style of a variety of adapted and authentic text forms, including fictional, informational, graphic, and media forms",
                    "Intercultural Understanding: demonstrate an understanding of information in texts in the target language about aspects of culture in diverse communities where the target language is spoken and other communities around the world, and of sociolinguistic conventions in the target language used in a variety of situations and communities.",
                ]
            },
            {
                title: "Writing",
                overall: [
                    "Purpose, Audience, and Form: write texts in the target language for different purposes and audiences, using a variety of forms and knowledge of language structures and conventions of the written language appropriate for this course;",
                    "The Writing Process: use the stages of the writing process – including pre-writing, producing drafts, revising, editing, and publishing – to develop and organize content, clarify ideas and expression, correct errors, and present their written work in the target language effectively;",
                    "Intercultural Understanding: in their written work in the target language, demonstrate an awareness of aspects of culture in diverse communities where the target language is spoken and other communities around the world, and of the appropriate use of sociolinguistic conventions in the target language in a variety of situations.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Teachers will bring enthusiasm and varied teaching and assessment approaches to the classroom, addressing individual students needs and ensuring sound learning opportunities for every student. The activities offered should enable students to relate and apply these concepts to the social, environmental, and economical conditions and concerns of the world in which they live. Opportunities to relate knowledge and skills to these wider contexts will motivate students to learn in a meaningful way and to become life-long learners. ",
        p2: "Teachers will help students understand that problem solving of any kind often requires a considerable expenditure of time and energy and a good deal of perseverance. Teachers also will encourage students to investigate, to reason, to explore alternative solutions and to take the risks necessary to become successful problem solvers. Effective instructional approaches and learning activities draw on students prior knowledge, capture their interest, and encourage meaningful practise both inside and outside the classroom. Students will be engaged when they are able to see the connection between the scientific concepts they are learning and their application in the world around them and in real-life situations. Due to its importance, students will have opportunities to learn in a variety of ways- individually, cooperatively, independently, with teacher direction, through hands-on experiences, and through examples followed by practice. The approaches and strategies teachers use will vary according to both the object of the learning and the needs of the students. Teachers will accomplish this in online environment with the use of: virtual labs, online simulations, animations, videos, discussion forums, live chat and other interactive objects.",
        lst: [
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Media_Art: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}