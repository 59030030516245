export const ENG4U = {
    title: "English",
    description: {
        code: "ENG4U",
        department: "English",
        type: "University",
        credit: "1.0",
        grade: "12",
        prerequisite: "ENG3U, English, Grade 11, University",
        document: "English, The Ontario Curriculum, Grades 11 and 12, 2007",
        link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/english1112currb.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course emphasizes the consolidation of the literacy, communication, and critical and creative thinking skills necessary for academic and daily life success. Students will analyze a range of challenging literary texts from various periods, countries, and cultures, interpret and evaluate informational and graphic texts, and create oral, written, and media texts in various forms. An important focus will be on using academic language coherently and confidently, selecting the reading strategies best suited to particular texts and particular purposes for reading, and developing greater control in writing. The course is intended to prepare students for university, college, or the workplace.",
    },
    outline: {
        title: "English ENG4U - Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Nonfiction and Short Stories",
                hour: "20 Hours",
                content: "A diverse collection of short stories and articles provides students with the opportunity to read a wide variety of texts and to explore, discuss, and write. Topics focused on include P.A.C.T.S, critical analysis, research, deeper analysis of themes, integrating support. Students will review rhetorical devices and be taught additional higher-level literary devices. They will be exposed to various topics that will help them in the succeeding units.",
            },
            {
                title: "Unit Two: Research and Grammar",
                hour: "20 Hours",
                contetn: "In this unit, students will be guided in a university-level research proposal. They will learn about qualitative and quantitative research to choose their research topic. They will also learn how to write research questions and thesis statements. Lastly, they will also learn about literature review and the MLA Citation Style as they check their research resources.",
            },
            {
                title: " Unit Three: Novel Study: Children of Blood and Bone",
                hour: "20 Hours",
                content: "Students will read a longer work of fiction and participate in lessons and activities to become familiar with the era the novel takes place in, explore various themes, write a character analysis, create presentations and discussion topics where they will evaluate on observation and conversation and finally write a formal literary essay with focus on structure, developing a solid thesis and blueprint, using a variety of sentence structures in their writing, deeper analysis and finally integrating transition words and phrases to improve the flow and quality of their writing. There will be direct and constant feedback from the teacher throughout the process.",
            },
            {
                title: "Unit Four: Media Studies",
                hour: "15 Hours",
                content: "Students will learn about the types of media and answer the worksheet provided. They will also learn about Media Literacy, different communication theories and analyze their favourite movie. Other than that, they will also be introduced to Media Production and participate in the discussion forum. Finally, they will write a script regarding the PSA and accomplish the unit summative to apply their learnings.",
            },
            {
                title: "Unit Five: Independent Novel Study ",
                hour: "15 Hours",
                content: "Students will create a quote analysis about their chosen novel. They will also learn about the different literary theories and apply them to their novel choice analysis. This unit prepares the student intensively for the RST.",
            },
            {
                title: " Rich Summative Task",
                hour: "10 Hours",
                content: "This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through the product and conversation observation.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "ENG4U – English,Grade 12, University Preparation",
        content: [
            {
                title: "Oral Communication",
                overall: [
                    "Listening to Understand: listen in order to understand and respond appropriately in a variety of situations for a variety of purposes;",
                    "Speaking to Communicate: use speaking skills and strategies appropriately to communicate with different audiences for a variety of purposes;",
                    "Reflect on and identify their strengths as listeners and speakers, areas for improvement, and the strategies they found most helpful in oral communication situations.",
                ],
            },
            {
                title: "Reading And Literature Studies",
                overall: [
                    "Reading for Meaning: Read and demonstrate an understanding of a variety of literary, informational, and graphic texts, using a range of strategies to construct meaning;",
                    "Understanding Form and Style: Recognize a variety of text forms, text features, and stylistic elements and demonstrate understanding of how they help communicate meaning;",
                    "Reading With Fluency: use knowledge of words and cueing systems to read fluently;",
                    "Reflect on and identify their strengths as readers, areas for improvement, and the strategies they found most helpful before, during, and after reading."
                ]
            },
            {
                title: "Writing",
                overall: [
                    "Developing and Organizing Content: Generate, gather, and organize ideas and information to write for an intended purpose and audience;",
                    "Using Knowledge of Form and Style: Draft and revise their writing, using a variety of literary, informational, and graphic forms and stylistic elements appropriate for the purpose and audience;",
                    "Applying Knowledge of Conventions: Use editing, proofreading, and publishing skills and strategies, and knowledge of language conventions, to correct errors, refine expression, and present their work effectively;",
                    "Reflecting on Skills and Strategies: Reflect on and identify their strengths as writers, areas for improvement, and the strategies they found most helpful at different stages in the writing process."
                ]
            },
            {
                title: "Media Studies",
                overall: [
                    "Understanding Media Texts: Demonstrate an understanding of a variety of media texts;",
                    "Understanding Media Forms, Conventions, and Techniques: Iidentify some media forms and explain how the conventions and techniques associated with them are used to create meaning",
                    "Creating Media Texts: Ccreate a variety of media texts for different purposes and audiences, using appropriate forms, conventions, and techniques;",
                    "Reflecting on Skills and Strategies: Rreflect on and identify their strengths as media interpreters and creators, areas for improvement, and the strategies they found most helpful in understanding and creating media texts."
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "English 4U provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent modules, applying previously acquired skills in ENG3U such as writing literary essays, research proposals, and critical analysis of relevant teacher-selected novels. Presentation techniques form the basis of study as students create oral presentations through screencasts, social media, and audio files in conjunction with media-related assignments that reflect their communication and literary theory studies.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Reading, Writing, and Oral Communication strategies enhance students’ literacy skills, especially for complex texts.",
            "Videos in the course illustrate topics such as literary devices, research methods, MLA formatting, novel studies, advertisements and public service announcements.",
            "Teaching on Children of Blood and Bone leads students through the novel while introducing racial discrimination and social and class inequality. Students connect these themes to real-life issues and situations.",
            "Scaffolding longer writing assignments (literary essays and research proposals) allow students to work with the process of creating thesis statements, outlines, drafts, and final copies. Teacher feedback at each level enables students to improve both style and content in their written pieces.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            English: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}