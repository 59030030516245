export const SNC1W = {
    title: "Science",
    description: {
        code: "SNC1W",
        department: "Science",
        type: "Academic",
        grade: "9",
        credit: "1.0",
        prerequisite: "None",
        document: "Science, The Ontario Curriculum, Grades 11 and 12, 2008",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/2009science11_12.pdf",
        developer: "KAI School",
        date: 2020 - 2021,
        content: "This course enables students to develop their understanding of basic concepts in biology, chemistry, earth and space science, and physics, and to relate science to technology, society, and the environment. Throughout the course, students will develop their skills in the processes of scientific investigation. Students will acquire an understanding of scientific theories and conduct investigations related to sustainable ecosystems; atomic and molecular structures and the properties of elements and compounds; the study of the universe and its properties and components; and the principles of electricity.",
    },
    outline: {
        title: "SNC1W, Grade 9 Science",
        units: [
            {
                title: "Unit One: Kinematics",
                hour: "22 Hours",
                content: "Students will demonstrate an understanding of uniform and non-uniform linear motion, in one and two dimensions. They will investigate, in qualitative and quantitative terms, uniform and non-uniform motion, and solve related problems. They will analyse technologies that apply concepts related to kinematics, and assess the technologies’ social and environmental impact.",
            },
            {
                title: "Unit Two: Forces",
                hour: "20 Hours",
                contetn: "Students will demonstrate an understanding of the relationship between changes in velocity and unbalanced forces in one dimension. They will investigate qualitatively and quantitatively net force, acceleration, and mass; and solve related problems. They will analyse and propose improvements to technologies that apply concepts related to dynamics and Newton’s laws, and assess the technologies’ social and environmental impact.",
            },
            {
                title: "Unit Three:Energy and Society",
                hour: "19 Hours",
                content: "Students will demonstrate an understanding of work, efficiency, power, gravitational potential energy, kinetic energy, nuclear energy, and thermal energy and its transfer as heat. They will investigate energy transformations and the law of conservation of energy, and solve related problems. They will analyse technologies that apply principles of, and concepts related to energy transformations, and assess the technologies’ social and environmental impact.",
            },
            {
                title: "Unit Four: Waves and Sounds",
                hour: "20 Hours",
                content: "Students will demonstrate an understanding of the properties of mechanical waves and sound and of the principles underlying their production, transmission, interaction, and reception. They will investigate the properties of mechanical waves and sound, and solve related problems. They will analyse how mechanical waves and sound affect technology, structures, society, and the environment, and assess ways of reducing their negative side effects.",
            },
            {
                title: "Unit Five: Electromagnetism",
                hour: "20 Hours",
                content: "Students will demonstrate an understanding of the properties of magnetic fields, the principles of current and electron flow, and the operation of selected technologies that use the properties and principles to produce and transmit electrical energy. They will investigate magnetic fields and electric circuits and solve related problems. They will analyse the social, economic and environmental impact of electrical energy production and technologies related to electromagnetism, and propose ways to improve the sustainability of electrical energy production.",
            },
            {
                title: "RST and Final Exam 30%",
                hour: "",
                content: "This is a proctored exam worth 30% of your final grade.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "SNC1W,Grade 9, Science",
        content: [
            {
                title: "Creating and Presenting",
                overall: [
                    "The Creative Process: apply the stages of the creative process when performing notated and/or improvised music and composing and/or arranging music;",
                    "The Elements of Music: apply elements of music when performing notated and improvised music and composing and/or arranging music;",
                    "Vocal Music Techniques: demonstrate an understanding of the Vocal Music techniques and movement vocabularies of a variety of Vocal Music forms from around the world;",
                    "Techniques and Technologies: use a variety of techniques and technological tools when performing music and composing and/or arranging music.",
                ]
            },
            {
                title: "Reflecting, Responding and Analysing",
                overall: [
                    "The Critical Analysis Process: use the critical analysis process when responding to, analysing, reflecting on, and interpreting musics;",
                    "Music and Society: demonstrate an understanding of how traditional, commercial, and art music reflect the society in which they were created and how they have affected communities or cultures;",
                    "Skills and Personal Growth: demonstrate an understanding of how performing, creating, and critically analysing music has affected their skills and personal development;",
                    "Connections Beyond the Classroom: identify and describe various opportunities for continued engagement in music.",
                ]
            },
            {
                title: "Foundations",
                overall: [
                    "Theory and Terminology: demonstrate an understanding of music theory with respect to concepts of notation and the elements and other components of music, and use appropriate terminology relating to them; ",
                    "Characteristics and Development of Music: demonstrate an understanding of the history of some musical forms and of characteristics of types of music from around the world; ",
                    "Conventions and Responsible Practices: demonstrate an understanding of responsible practices and performance conventions relating to music.",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Using a variety of instructional strategies, the teacher will provide numerous opportunities for students to develop skills of inquiry, problem solving, and communication as they investigate and learn fundamental concepts. The integration of critical thinking and critical inquiry skills will provide a powerful tool for reasoning and problem solving, and is reflected in a meaningful blend of both process and content. ",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Creating and presenting, Reflecting, Responding and Analysing and foundations strategies enhance students to develop and refine their critical and creative skills, problem-solving skills, and communication skills, while engaged in arts activities, projects, and exploration.",
            "Videos in the course illustrate topics such as drawing techniques, photography, digital media and art history lectures.",
            "Scaffolding longer visual arts-related assignments allow students to work with the process of creating. Teacher feedback at each level enables students to improve both style and content in their art work projects.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their media arts projects.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Vocal_Music: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },

}