export const GLC2O = {
    title: "Career Studies",
    description: {
        code: "GLC2O",
        department: "Guidance and Career Education",
        type: "Open",
        credit: "0.5",
        grade: "10",
        prerequisite: "None",
        document: "Guidance and Career Education, The Ontario Curriculum, Grades 9 and 10, 2006 (revised)",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/guidance910currb.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course teaches students how to develop and achieve personal goals for future learning, work, and community involvement. Students will assess their interests, skills, and characteristics and investigate current economic and workplace trends, work opportunities, and ways to search for work.The course explores postsecondary learning and career options, prepares students for managing work and life transitions, and helps students focus on their goals through the development of a career plan. ",
    },
    outline: {
        title: "GLC2O Career Studies- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Developing Skills, Strategies, and Habits Needed to Succeed",
                hour: "35 Hours",
                content: "In this unit, students analyze current strengths and interests. Students will learn how to be resilient and persevere in the face of adversity in their daily lives, schools, and workplaces. Learn the importance of stress management in your personal and professional lives in order to maintain a healthy balance. Learn the significance of setting personal, social, educational, and career/life goals, as well as how to develop methods for assessing how well those goals are met.",
            },
            {
                title: "Unit Two: Exploring and Preparing for the World of Work",
                hour: "35 Hours",
                contetn: "This unit is about making a plan based on what students know about themselves and what is available. The unit discusses how to plan a path for education, job experience, and acquiring skills that will help them stay competitive. They discuss the benefits of, and strategies for, networking in person and online. Finally, there is a section dedicated to the more realistic aspects of the job search, such as job futures analysis, what to do if you are laid off, and strategies for planning alternate career paths without jeopardizing their primary goals.",
            },
            {
                title: " Unit Three: Planning and Financial Management to Meet Post-Secondary Goals",
                hour: "34 Hours",
                content: "This unit teaches students how to pay, save, and budget for their future. It explores  the expenses they will face in their first year on their own, as well as post-secondary education costs, financial planning, sources of income, loan, bursary, and banking options, and creating a post-secondary that will help them achieve their financial goals.",
            },
            {
                title: "Exam/RST",
                hour: "6 Hours",
                content: "The RST is worth 30% of the final grade. This is a 3 part career portfolio.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "GLC2O: Career Studies ,Grade 10m Open",
        content: [
            {
                title: "Personal Management",
                overall: [
                    "use a self-assessment process to develop a personal profile for use in career development planning;",
                    "evaluate and apply the personal- management skills and characteristics needed for school success, document them in their portfolio, and demonstrate their use in a variety of settings;",
                    "demonstrate effective use of interpersonal skills within a variety of settings.",
                ],
            },
            {
                title: "Exploration Of Opportunities",
                overall: [
                    "use a research process to locate and select relevant career information from a variety of sources for inclusion in a portfolio;",
                    "identify current trends in society and the economy and describe their effect on work opportunities and work environments;",
                    "identify a broad range of options for present and future learning, work, and community involvement.",
                ]
            },
            {
                title: "Preparation For Transitions And Change",
                overall: [
                    "use appropriate decision-making and planning processes to set goals and develop a career plan;",
                    "analyse changes taking place in their personal lives, their community, and the economy, and identify strategies to facilitate smooth transitions during change;",
                    "demonstrate an understanding of, and the ability to prepare for, the job- search process.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "This course gives students the opportunity to develop the skills, knowledge, and habits that will support them in their education and career/life planning. Students will learn about global work trends, and seek opportunities within the school and community to expand and strengthen their transferable skills and their ability to adapt to the changing world of work. On the basis of exploration, reflective practice, and decision-making processes, students will make connections between their skills, interests, and values and their postsecondary options, whether in apprenticeship training, college, community living, university, or the workplace. They will set goals and create a plan for their first postsecondary year. As part of their preparation for the future, they will learn about personal financial management – including the variety of saving and borrowing tools available to them and how to use them to their advantage – and develop a budget for their first year after secondary school.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Helping students become self-directed, lifelong learners is a fundamental aim of the guidance and career education curriculum.",
            "Engaged in active and experiential learning strategies, they tend to retain knowledge for longer periods and develop meaningful skills. ",
            "Active and experiential learning strategies also enable students to apply their knowledge and skills to real-life issues and situations.",
            "Opportunities to learn in a variety of ways- individually, cooperatively, independently, with teacher direction, through hands-on experiences, and through examples followed by practice.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Career_Studies: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}