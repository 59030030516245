import React, { useState, useEffect } from 'react';
import "../../styles/CompStyle/PhotoGallery.css"

const images = [
    'imggallery/IMG_9758.jpg',
    'imggallery/IMG_9766.jpg',
    'imggallery/IMG_9771.jpg',
    'imggallery/IMG_9773.jpg',
    'imggallery/IMG_9775.jpg',
    // Add more images as needed
];

function ImageGallery() {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImage(currentImage => (currentImage + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const handleNextClick = () => {
        setCurrentImage((currentImage + 1) % images.length); // Move to the next image
    };

    const handlePrevClick = () => {
        setCurrentImage((currentImage + images.length - 1) % images.length); // Move to the previous image
    };

    const handleDotClick = index => {
        setCurrentImage(index);
    };

    return (
        <div className="image-gallery" >
            <div className='gallery-container'>
                <button onClick={handlePrevClick}>&lt;</button>
                <img
                    src={images[currentImage]}
                    alt="Gallery"
                />
                <button onClick={handleNextClick}>&gt;</button>
            </div>

            <div className="dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
            </div>
        </div>
    );
}

export default ImageGallery;
