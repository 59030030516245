
import "../styles/GradeCourse.css";

export default function GradeCourseOverviewComp(props) {


    const { code, name, collab, link } = props.course;
    const truncatedCode = code.substring(0, 3); // Get the first three letters of code
    const imgSrc = `/img/academic_logo/${truncatedCode}.png`;
    return (
        <div className="grade-course">
            <div className="inbox-grid-wrapper">
                <div className="col-left">
                    <img src={imgSrc} alt="random"></img>
                </div>
                <div className="col-right">
                    <h3>{code}</h3>
                    <div className="line" />
                    <p>{name}</p>
                    <p>{collab ? "(Collaborative Course)" : ""}</p>
                </div>
            </div>
            <div className="view-details">
            <a href={link} target="_blank" rel="noreferrer">View Details</a>
            </div>



        </div>
    );
};