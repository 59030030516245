export const SPH3U = {
    title: "Physics",
    description: {
        code: "SPH3U",
        department: "Science",
        type: "University",
        credit: "1.0",
        prerequisite: "SNC2D, Science, Grade 10, Academic",
        document: "Science, The Ontario Curriculum, Grades 11 and 12, 2008",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/2009science11_12.pdf",
        developer: "KAI School",
        date: 2021,
        content: "This course develops students’ understanding of the basic concepts of physics. Students will explore kinematics, with an emphasis on linear motion; different kinds of forces; energy transformations; the properties of mechanical waves and sound; and electricity and magnetism. They will enhance their scientific investigation skills as they test the laws of physics. In addition, they will analyse the interrelationships between physics and technology, and consider the impact of technological applications of physics on society and the environment.",
    },
    outline: {
        title: "Physics SPH3U- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Kinematics",
                hour: "22 Hours",
                content: "Students will demonstrate an understanding of uniform and non-uniform linear motion, in one and two dimensions. They will investigate, in qualitative and quantitative terms, uniform and non-uniform motion, and solve related problems. They will analyse technologies that apply concepts related to kinematics, and assess the technologies’ social and environmental impact.",
            },
            {
                title: "Unit Two: Forces",
                hour: "20 Hours",
                contetn: "Students will demonstrate an understanding of the relationship between changes in velocity and unbalanced forces in one dimension. They will investigate qualitatively and quantitatively net force, acceleration, and mass; and solve related problems. They will analyse and propose improvements to technologies that apply concepts related to dynamics and Newton’s laws, and assess the technologies’ social and environmental impact.",
            },
            {
                title: "Unit Three:Energy and Society",
                hour: "19 Hours",
                content: "Students will demonstrate an understanding of work, efficiency, power, gravitational potential energy, kinetic energy, nuclear energy, and thermal energy and its transfer as heat. They will investigate energy transformations and the law of conservation of energy, and solve related problems. They will analyse technologies that apply principles of, and concepts related to energy transformations, and assess the technologies’ social and environmental impact.",
            },
            {
                title: "Unit Four: Waves and Sounds",
                hour: "20 Hours",
                content: "Students will demonstrate an understanding of the properties of mechanical waves and sound and of the principles underlying their production, transmission, interaction, and reception. They will investigate the properties of mechanical waves and sound, and solve related problems. They will analyse how mechanical waves and sound affect technology, structures, society, and the environment, and assess ways of reducing their negative side effects.",
            },
            {
                title: "Unit Five: Electromagnetism",
                hour: "20 Hours",
                content: "Students will demonstrate an understanding of the properties of magnetic fields, the principles of current and electron flow, and the operation of selected technologies that use the properties and principles to produce and transmit electrical energy. They will investigate magnetic fields and electric circuits and solve related problems. They will analyse the social, economic and environmental impact of electrical energy production and technologies related to electromagnetism, and propose ways to improve the sustainability of electrical energy production.",
            },
            {
                title: "RST and Final Exam 30%",
                hour: "",
                content: "This is a proctored exam worth 30% of your final grade.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "SPH3U-Physics Grade 11, University Preparation",
        content: [
            {
                title: "Scientific Investigation Skills And Career Exploration",
                overall: [
                    "Demonstrate scientific investigation skills (related to both inquiry and research) in the four areas of skills (initiating and planning, performing and recording, analysing and interpreting, and communicating);",
                    "Identify and describe careers related to the fields of science under study, and describe the contributions of scientists, including Canadians, to those fields.",
                ],
            },
            {
                title: "Kinematics",
                overall: [
                    "Analyse technologies that apply concepts related to kinematics, and assess the technologies’ social and environmental impact;",
                    "Investigate, in qualitative and quantitative terms, uniform and non-uniform linear motion, and solve related problems;",
                    "Demonstrate an understanding of uniform and non-uniform linear motion, in one and two dimensions",
                ]
            },
            {
                title: "Forces",
                overall: [
                    "Analyse and propose improvements to technologies that apply concepts related to dynamics and Newton’s laws, and assess the technologies’ social and environmental impact;",
                    "Investigate, in qualitative and quantitative terms, net force, acceleration, and mass, and solve related problems;",
                    "Demonstrate an understanding of the relationship between changes in velocity and unbalanced forces in one dimension.",
                ]
            },
            {
                title: "Energy And Society",
                overall: [
                    "Analyse technologies that apply principles of and concepts related to energy transformations, and assess the technologies’ social and environmental impact;",
                    "Investigate energy transformations and the law of conservation of energy, and solve related problems;",
                    "Demonstrate an understanding of work, efficiency, power, gravitational potential energy, kinetic energy, nuclear energy, and thermal energy and its transfer (heat).",
                ]
            },
            {
                title: "Waves And Sound",
                overall: [
                    "analyse how mechanical waves and sound affect technology, structures, society, and the environment, and assess ways of reducing their negative effects;",
                    "investigate, in qualitative and quantitative terms, the properties of mechanical waves and sound, and solve related problems;",
                    "demonstrate an understanding of the properties of mechanical waves and sound and of the principles underlying their production transmission, interaction, and reception.",
                ]
            },
            {
                title: "Electricity And Magnetism",
                overall: [
                    "Analyse the social, economic, and environmental impact of electrical energy production and technologies related to electromagnetism, and propose ways to improve the sustainability of electrical energy production;",
                    "Investigate, in qualitative and quantitative terms, magnetic fields and electric circuits, and solve related problems;",
                    "Demonstrate an understanding of the properties of magnetic fields, the principles of current and electron flow, and the operation of selected technologies that use these properties and principles to produce and transmit electrical energy",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "",
        p2: "",
        lst: [
            "This course's main goal is to assist students in learning science and applying their knowledge and skills. Language is used effectively, confidently, and flexibly by course writers.",
            "Effective instructional strategies and learning activities build on students' prior knowledge, stimulate their attention, and provide opportunities for meaningful practice. Students will be more interested if they can understand the link between the scientific concepts they are studying and how they are used in the world around them and in real-life situations.",
            "Teachers will design activities and challenges that actively involve students in investigations that respect the ideas and talents they bring to the table while also improving their conceptual understandings and vital abilities. Students will be able to employ scientific reasoning throughout their life if they understand huge ideas.",
            "Contextualized teaching and learning also gives teachers valuable insights into their students' thinking, conceptual grasp, and ability to reflect on their work. This knowledge enables teachers to provide assistance to students in order to improve their learning. To meet a diversity of learning styles, interests, and skill levels, a number of instructional tactics are employed to give learning opportunities.",
            "The learning goals and objectives in teaching physics are to develop students problem solving, reasoning, and meta-cognitive skills and become independent learners and excellent problem solvers in line with that teachers needs to know the student well by building rapport in order to gauge the capability of the student in order to plan an effective teaching method on how to make the students more engaged in different activities provided based on student needs.",
            "Incorporating interactive tools and recorded video discussion that help students stay focused and engaged in the class. Additionally, it aids students in mastering the physics concept.",
            "Virtual laboratories, simulations, and journal papers have all been used. Virtual laboratories, such as gizmos and labster, are one of the most effective ways to connect students to the actual world. Because of the dangers and limited face-to-face connection, students are able to undertake numerous experiments that are difficult to perform in traditional laboratories.",
            "Virtual clues can easily supplement auditory information, allowing students to better engage with ideas.",
            "The multi-sensory experiences improve their understanding and memorization skills. Drawings, diagrams, and image analysis are used to aid theory, as well as laying up instances to demonstrate its application side.",
            "The sequence of lab procedures can be better taught using pictures with words.",
            "Scaffolding on their laboratory activities and unit projects provide the students support level along the process of learning given by the teacher. Teacher feedback at each level enables students to improve both style and content in their written pieces.",
            "In physics, students must be familiar with the formulas for many types of physics problems, as one of the time-tested strategies of deepening neural pathways is to provide mnemonic devices. Linguistic hacks and rhymes are entertaining and active strategies to keep students engaged in the course.",
            "Students who are not challenged are both recipes for a lack of learning, so the quality and quantity of homework should be evaluated. Giving immediate feedback is the greatest way to keep track of students’' progress."
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Physics: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, KAI School will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },


}